import _ from 'lodash';
import React from 'react';
import AvatarTooltip from '../components/layout/avatars/AvatarTooltip';
import { capitalizeWords, formatDate, getDaysBetween, getSafeArray } from './helpers';
import { statusesConfig } from '../constants/status';
import CRITICALITY from '../constants/criticality';
import { issueTypes } from '../constants/issues'
import { USER_TYPES } from '../constants/common';
import { scanStatusMap } from '../constants/common';
import { parseTaggingsList } from './tagsHelper';

export const renderIssueDate = (fieldName, issue) => {
    if (!issue) return null;
    const date = issue[fieldName];
    return <div>{formatDate(date)}</div>
}

export const renderIssuesAssignees = (assignees) => {
    return assignees.map((assignee) => {
        const { profileImage, public_id, id } = assignee;
        return (
            <AvatarTooltip
                key={`assignee-${public_id || id}`}
                id={`user-tooltip-${public_id || id}`}
                user={assignee}
                profileImage={profileImage}
            />
        )
    });
}

export const getStatusLabelByUserType = (status, userType) => {
    switch(userType) {
        case USER_TYPES.CYDEKICK_TEAM: {
            return status?.cydekickLabel || status?.label
        }
        case USER_TYPES.MEMBER: {
            return status?.memberLabel || status?.label
        }
        default: {
            return status?.label
        }
    }
}

export const getStatusLabelById = (id, userType) => {
    const parsedId = parseInt(id);

    const status = statusesConfig.find((status) => status?.id === parsedId);

    return getStatusLabelByUserType(status, userType)
}

export const getIssueTotalsPayload = (state, data = {}, userType) => {
    const statusesArray = Object.keys(data?.by_status).map(key => {
        return {
            ...data?.by_status[key] || {},
            id: key
        }
    })
    return {
        totalsBy: {
            ...state.totalsBy,
            criticality: _.orderBy(Object.keys(data?.by_severity).map(key => {
                const severities = data?.by_severity[key] || {}
                return {
                    ...severities,
                    id: parseInt(key),
                    className: CRITICALITY[key]?.className
                }
            }), 'id', 'desc'),
            status: formatIssueStatuses(statusesArray, userType)
        }
    }
}

export const formatIssueStatuses = (statuses, userType) => {
    const safeStatuses = getSafeArray(statuses);
    const isMemberType = userType === USER_TYPES.MEMBER;

    const filteredStatuses = safeStatuses.reduce((statusesArray, status) => {
        const relabeledStatus = getStatusLabelById(status?.id, userType)
        const statusId = parseInt(status?.id);

        if(isMemberType && relabeledStatus === 'To Do') {
            const toDoIndex = statusesArray.findIndex((element) => element?.label === relabeledStatus);
            if(toDoIndex > -1) {
                const total = parseInt(statusesArray[toDoIndex]?.total || 0)
                if(status?.label === 'To Do') {
                    status.total = total + parseInt(status?.total || 0)
                } else {
                    statusesArray[toDoIndex].total = total + parseInt(status?.total || 0)
                }
            }
        }

        const shouldHidePending = isMemberType && status?.label === 'Pending Review';
        const hidden =  shouldHidePending ? true : status?.hidden;

        return [
            ...statusesArray,
            {
                ...status,
                id: statusId,
                label: relabeledStatus,
                hidden,
            }
        ]
    }, [])
    return filteredStatuses
}

export const getIssuesConfig = (data = {}, userType) => {
    const {
        companies = {},
        users = {},
        priorities = [],
        statuses = [],
        severities = [],
        solutions = [],
    } = data;
    return {
        modalOptions: {
            companies: _.sortBy(companies?.data, [company => company.name?.toLowerCase()]) || [],
            users: {
                ...users,
                cydekicks: _.sortBy(users?.cydekicks, [cydekick => cydekick.full_name?.toLowerCase()])
            } || {},
            solutions: _.sortBy(solutions, [solution => solution.abbreviation?.toLowerCase()]) || [],
            statuses: formatIssueStatuses(statuses, userType),
            criticalities: _.orderBy(severities.map(severity => {
                return {
                    ...severity,
                    id: parseInt(severity?.id),
                    className: CRITICALITY[severity?.id]?.className
                }
            }), 'id', 'desc'),
            priorities,
        }
    }
}

export const getPriorityById = (id, priorities = []) => {
    return priorities.find((priority) => priority.id === id);
}

//  Function to get issues criticality name
export const getIssueCriticalityClassName = (criticality) => {
    if (!criticality) { return null; }
    return CRITICALITY[criticality]?.className;
};

//  Function to map issues simple options to select options
export const mapIssuesOptionsToSelectOptions = (issuesOptions = []) => {
    const safeArray = getSafeArray(issuesOptions);
    return safeArray.map((item) => ({ value: item?.id, label: item?.label }));
};

//  Function to find selected option
export const findIssuSelectedOption = (selectedId, options) => {
    const safeArray = getSafeArray(options);
    return safeArray.find((item) => item.value === selectedId);
}

//  Function to get issue create payload
export const getGRCCreatePayload = (payload) => {
    const newPayload = { ...payload };
    delete newPayload.company_name;
    delete newPayload.sub_tasks;
    delete newPayload.parent;
    delete newPayload.overdue;
    delete newPayload.taggings;
    delete newPayload.tags;
    delete newPayload.files;
    delete newPayload.company_public_id;
    return newPayload;
};

//  Function to get issue update paylaod
export const getGRCUpdatePayload = (payload) => {
    const newPayload = { ...payload };
    delete newPayload.id;
    delete newPayload.public_id;
    delete newPayload.code;
    delete newPayload.company_id;
    delete newPayload.company_name;
    delete newPayload.updated_at;
    delete newPayload.created_at;
    delete newPayload.parent_issue_id;
    delete newPayload.sub_tasks;
    delete newPayload.parent;
    delete newPayload.overdue;
    delete newPayload.taggings;
    delete newPayload.tags;
    delete newPayload.files;
    delete newPayload.company_public_id;
    return newPayload;
};

//Function to determine if Member Review filter should be displayed depending on the IssueType (must be hidden on GRC)
export const getStatusFilterByIssuesType = (element, issueType) => {
    if (issueType === issueTypes.GRC) {
        return element.hidden === false && element.label !== getStatusLabelById(3, null);
    } else {
        return element.hidden === false;
    }
};

export const getIssueLabelByType = (issueType = issueTypes.VULNERABILITIES, shouldCapitalize = false) => {
    const label = issueType === issueTypes.GRC ? 'tasks' : 'issues';
    return shouldCapitalize ? capitalizeWords(label) : label
}

export const getIssueStatusValueByUserType = (statusId, userType) => {
    const isMemberType = userType === USER_TYPES.MEMBER
    let selectedStatusValue = statusId;
    const status = statusesConfig.find(element => element?.id === statusId)
    if(isMemberType) {
        selectedStatusValue = status?.memberValue || statusId;
    }
    return selectedStatusValue
}

const getPendingStatusLabelByUserType = (userType) => {
    const pendingStatus = statusesConfig.find(status => status?.label === 'Pending Review')
    return getStatusLabelByUserType(pendingStatus, userType)
}

// Function to rename Pending Review status
export const relabelCommentsPendingReview = (comments, userType) => {
    const newLabel = `Status updated to ${getPendingStatusLabelByUserType(userType)}`;
    const pendingReviewReg = new RegExp('Status updated to Pending Review', 'g')
    return getSafeArray(comments).map((comment) => {
        return {
            ...comment,
            content: {
                ...comment?.content || {},
                blocks: getSafeArray(comment?.content?.blocks).map((block) => {
                    return {
                        ...block,
                        text: block?.text?.replace(pendingReviewReg, newLabel)
                    }
                })
            }
        }
    })
}

export const getScanRollupEmptyLabel = (scanStatus, isFreemium) => {
    const scanNotRequested = scanStatusMap.NOT_REQUESTED_SCAN === scanStatus;
    const freemiumLabel = scanNotRequested ? "Request your monthly free vulnerability scan" : "Your Scan has been requested";
    return !isFreemium ? null : freemiumLabel;
};

export const getScanRollupClassName = (scanStatus, isFreemium) => {
    const scanNotRequested = scanStatusMap.NOT_REQUESTED_SCAN === scanStatus;
    return !isFreemium || scanNotRequested ? null : "scan-requested-message";
};

export const getStatusClassNameByLabel = (label) => {
    return label?.replace(" ", "-")?.toLowerCase() || 'light-gray';
}

export const getIssuesExistenceTime = (creationDate, resolutionDate) => {
    const daysCount = creationDate ? getDaysBetween(creationDate, resolutionDate || new Date()) : 0;
    return `${daysCount} day${daysCount !== 1 ? 's' : ''}`
}

export const isValidStatus = (element, issueType) => {
    return element?.label !== 'Re Opened' && getStatusFilterByIssuesType(element, issueType);
}

export const isIssueVulType = (issueType) => {
 return issueType === issueTypes.GROUP_INDIVIDUAL || issueType === issueTypes.VULNERABILITIES
}
export const getSelectedIssue = (payload = {}) => {
    return {
        ...payload,
        code: payload.code,
        id: payload.id,
        companyId: payload.company_id,
        companyName: payload.company.name,
        parent: payload.parent,
        name: payload.name,
        description: payload.description,
        os_version: payload?.entity?.device_entity?.os_type || '', // TODO: DB - redesign confirm attribute
        attachments: payload.files,
        status: payload.status,
        criticality: payload.severity,
        assignee: payload.assigned_user,
        solution: payload.solution,
        partner: payload.partner,
        updatedAt: payload.updated_at,
        createdAt: payload.created_at,
        etl: payload.etl_sync_id,
        remediationPlan: payload.remediation_plan,
        remediationPlanNotes: payload.remediaton_plan_notes,
        comments: {
            list: payload.comments?.list || []
        },
        subtasks: payload.sub_tasks || [],
        entity: payload?.entity,
        classification: payload.classification,
        confidence_level: payload.confidence_level,
        partner_unique_id: payload.partner_unique_id,
        tags: parseTaggingsList(payload?.taggings),
        partnerURL: payload.partner_url,
        extra_fields: payload.extra_fields,
        resolution_date: payload?.resolution_date,
        parent_issue_id: payload?.parent_issue_id,
        partner_fields: payload?.partner_fields
    }
}

export const getUpdatedIssue = (state = {}, payload = {}) => {
    return {
        ...state.selectedIssue,
        name: payload.name,
        description: payload.description,
        status: payload.status,
        criticality: payload.severity,
        assignee: payload.assigned_user,
        solution: payload.solution,
        updatedAt: payload.updated_at,
        remediationPlan: payload.remediation_plan,
        remediationPlanNotes: payload.remediaton_plan_notes,
        tags: parseTaggingsList(payload?.taggings),
        comments: {
            list: payload.comments?.list || []
        }
    }
}

export const getIssuesStoreEntryByIssueType = (issueType) => {
    switch (issueType) {
        case issueTypes.GRC:
            return 'grcIssues'
        case issueTypes.GROUP_INDIVIDUAL:
            return 'issuesGroup'
        case issueTypes.VULNERABILITIES:
        default:
            return 'issues'
    }
}
