import React from 'react'
import { UncontrolledTooltip, Button } from 'reactstrap';
import { grcIssueChildTypeInfo, issueTypes, vulnerabilityIssueChildTypeInfo } from '../../constants/issues';
import { isIssueVulType } from '../../utils/issuesHelpers';

const IssueName = (props) => {
    const {
        name,
        code,
        parentIssueId,
        issueType = issueTypes.VULNERABILITIES,
        displayType = true,
        osVersion,
        customClassName = "",
        showIssueType = true,
    } = props;

    const renderTypeBadge = (typeInfo) => {
        if(!showIssueType) return null;
        const {
            code,
            parentIssueId,
            parentColor = 'light',
            childColor = 'bubbles',
            parentLabel = 'Issue',
            childLabel = 'Subtask'
        } = typeInfo;

        const targetId =`issue-type-${code}`;
        return (
            <>
            <Button
                id={targetId}
                color="light"
                className={`bg-${parentIssueId ? childColor : parentColor} issue-type-badge`}
                tabIndex="-1"
            >
                {parentIssueId ? childLabel?.charAt(0) : parentLabel?.charAt(0)}
            </Button>
            <UncontrolledTooltip placement="bottom" target={targetId}>
                {parentIssueId ? childLabel : parentLabel}
            </UncontrolledTooltip >
        </>
        )
    }

    const renderIssueType = () => {
        if(!displayType) return null;
        const childInfoType = isIssueVulType(issueType) ? vulnerabilityIssueChildTypeInfo : grcIssueChildTypeInfo;
        return renderTypeBadge({
            ...childInfoType,
            code,
            parentIssueId
        })
    }

    return (
        <div className='d-flex align-items-center'>
            {renderIssueType()}
            <div
                className={`td-issue-name td-multiline-ellipsis ${customClassName}`}
            >
                {`${name} ${osVersion ? `- ${osVersion}` : '' }`}
            </div>
        </div>
    )
}

export default IssueName
