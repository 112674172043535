export const riskReconSecurityDomains = {
  "rating_numeric": 9.4,
  "rating_v2": "A",
  "security_domain_ratings": {
    "software_patching": {
      "order": 1,
      "label": "Software Patching",
      "rating_v2": "a",
      "trend": 0,
      "rating": "proceed",
      "rating_numeric": 10
    },
    "web_app_security": {
      "order": 2,
      "label": "Application Security",
      "rating_v2": "A",
      "trend": 0,
      "rating": "stop",
      "rating_numeric": 9.2,
    },
    "web_encryption": {
      "order": 3,
      "label": "Web Encryption",
      "rating_v2": "a",
      "trend": 0,
      "rating": "proceed",
      "rating_numeric": 10,
    },
    "network_filtering": {
      "order": 4,
      "label": "Network Filtering",
      "rating_v2": "a",
      "trend": 0,
      "rating": "proceed",
      "rating_numeric": 10,
    },
    "data_loss": {
      "order": 5,
      "label": "Breach Events",
      "rating_v2": "a",
      "trend": 0,
      "rating": "proceed",
      "rating_numeric": 10
    },
    "threat_intell": {
      "order": 6,
      "label": "System Reputation",
      "rating_v2": "a",
      "trend": 0,
      "rating": "proceed",
      "rating_numeric": 10,
    },
    "email_security": {
      "order": 7,
      "label": "Email Security",
      "rating_v2": "a",
      "trend": 0,
      "rating": "proceed",
      "rating_numeric": 10
    },
    "dns_security": {
      "order": 8,
      "label": "DNS Security",
      "rating_v2": "a",
      "trend": 0,
      "rating": "proceed",
      "rating_numeric": 10
    },
    "system_hosting": {
      "order": 9,
      "label": "System Hosting",
      "rating_v2": "a",
      "trend": 0,
      "rating": "proceed",
      "rating_numeric": 10
    },
  }
};

export const riskDomainDisplayInfo = {
  "software_patching": {
    "label": "Software Patching",
    "description": "Identifies end-of-life and vulnerable software using CVSS rating for severity. Includes 4 criteria: App Server, OpenSSL, CMS, and Web Server Patching.",
    "order": 1,
  },
  "web_app_security": {
    "label": "Application Security",
    "description": "Assesses web apps for compliance with security practices via passive techniques. Contains 5 criteria: CMS Authentication, HTTP Security Headers, Threat Alerts, Encryption, and Malicious Code.",
    "order": 2,
  },
  "web_encryption": {
    "label": "Web Encryption",
    "description": "Analyzes web encryption configurations to ensure secure communications and system authenticity. Includes 6 criteria: Certificate Dates, Encryption Algorithms, Key Length, Protocols, and Subject.",
    "order": 3,
  },
  "network_filtering": {
    "label": "Network Filtering",
    "description": "Analyzes networks for Unsafe Services and IoT Devices, common vectors for compromises.",
    "order": 4,
  },
  "data_loss": {
    "label": "Breach Events",
    "description": "Summarizes recent breach events to identify gaps in prevention programs.",
    "order": 5,
  },
  "threat_intell": {
    "label": "System Reputation",
    "description": "Identifies systems communicating with hostile entities, with 7 criteria including C2 Servers, Botnets, and Phishing Sites.",
    "order": 6,
  },
  "email_security": {
    "label": "Email Security",
    "description": "Email Security Analyzes email service configurations for encryption and authentication. Includes Email Authentication (SPF or DKIM), Email Encryption (STARTTLS), and Hosting Providers.",
    "order": 7,
  },
  "dns_security": {
    "label": "DNS Security",
    "description": "Assesses controls against unauthorized domain record modification and DNS hosting provider fragmentation.",
    "order": 8,
  },
  "system_hosting": {
    "label": "System Hosting",
    "description": "Rates system hosting fragmentation and shared IP address usage for Internet attack surface insight. Geolocation data provided for information only.",
    "order": 9,
  },
}

export const riskReconIndustryTrends = {
  "analysis_percentile": {
    "overall": 0.9,
  },
  "industry_mean": {
    "overall": 8,
  },
  "industry": "Technology",
}