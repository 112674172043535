import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Row, Card, CardBody, Col } from 'reactstrap';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import IssuesList from '../components/issue-manager/IssuesList';
import IssueDetails from '../components/issue-manager/IssueDetails';
import RollupWidget from '../components/dashboard/cydekick-results/RollupWidget'
import {
  getIssue,
  getIssueModalOptions,
  onShowGRCDetails,
  setIssuesFilter,
} from '../actions/issues';
import makeFreemiumValidations from '../selectors/freemiumStatusSelector';
import filterTypes from '../constants/filterTypes';
import IssueDemoPreview from '../components/issue-manager/IssueDemoPreview';
import useMemoizedDispatch from '../hooks/useMemoizedDispatch';
import { rollUpWidgetsIds, scanStatusMap, issuesStickyDivsIds } from '../constants/common';
import { getParentPath, getUpdatedSearchParams } from '../utils/helpers';
import useSolutionTabs from '../hooks/useSolutionTabs';
import useScanValidation from '../hooks/useScanValidation';
import useStickyGroupHeight from '../hooks/useStickyGroupHeight';
import usePartnerManualActions from '../hooks/usePartnerManualActions';
import MainScrollableContainer from '../components/layout/MainScrollableContainer';
import { setBackLink } from '../actions/navigation';
import companyInfoSelector from '../selectors/companyInfoSelector';
import { issueTypes, criticalityColorsByLabel } from '../constants/issues';
import useCanAbility from '../hooks/useCanAbility';
import { CREATE_ACTION, PRIVILEGE_SOURCES } from '../constants/privileges';
import TrendChartWrapper from '../components/dashboard/charts/tendency-chart/TrendChartWrapper';
import { issuesCriticalityConfigColor } from '../constants/criticality';
import { tendencyChartPointsByTimeUnit } from '../constants/tendencyChart';
import API from '../api';
import useUserType from '../hooks/useUserType';
import { getScanRollupClassName, getScanRollupEmptyLabel } from '../utils/issuesHelpers';
import useIssueInfoByGroupParam from '../hooks/useIssueInfoByGroupParam';

//  TODO: split into subcomponents when more tabs are added to this component
const IssueManager = () => {
  //  Component hooks
  const location = useLocation();
  const history = useHistory();
  const { dispatch } = useMemoizedDispatch();
  const { isAuthorized, scanStatus } = useScanValidation();
  const { refs, getCurrentAccumulatedHeight } = useStickyGroupHeight({ elementsIds: issuesStickyDivsIds });
  const ability = useCanAbility();
  const userType = useUserType();
  const { group_id } = useParams();

  // Component variables
  const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE);
  // Component Hooks
  const {
    filters,
    issues,
    issueType,
  } = useIssueInfoByGroupParam();
  const isGroupSubroute = useMemo(() => issueType === issueTypes.GROUP_INDIVIDUAL, [issueType]);

  // Get solution tabs
  useSolutionTabs(isGroupSubroute);

  // Get partner manual actions
  usePartnerManualActions();

  useEffect(() => {
    //Set group id filter
    dispatch(setIssuesFilter('group_id', group_id, 'Group', filterTypes.single, null, true, issueTypes.GROUP_INDIVIDUAL))
  }, [group_id])

  //  Initializing API
  const { IssueAPI } = API;

  //  Did mount ref
  const didMountRef = useRef(null);
  const scrollableContainerRef = useRef(null);

  //  Watching redux store
  const getFreemiumValidations = makeFreemiumValidations();
  const freemiumStatus = useSelector((state) => state.auth.company?.freemium);
  const { renderSampleData } = useSelector((state) => getFreemiumValidations(state));
  const selectedSubTabValue = useSelector((state) => state?.navigation?.selectedSubtab?.value);
  const selectedSubTabLabel = useSelector((state) => state?.navigation?.selectedSubtab?.label);
  const activeImpersonation = useSelector((state) => !_.isNil(state.impersonation.companyId));
  const isMemberView = useSelector(state => state.impersonation.isMemberView);
  const companyName = useSelector((state) => companyInfoSelector(state, 'name'));

  //  Component state
  const [showIssueDetails, setShowIssueDetails] = useState(false);
  const [shouldExpandModal, setShouldExpandModal] = useState(true);
  const [isNestedRoute, setIsNestedRoute] = useState(false);
  const [rollupCollapsed, setRollupCollapsed] = useState(0);
  const [updatedPage, setUpdatedPage] = useState(null);
  const toggleIssueDetailsModal = (show) => {
    setShowIssueDetails(show || !showIssueDetails);
  }

  useEffect(() => {
    setShouldExpandModal(true);
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get("selectedIssue");

    if (queryParams.has('details') && code) {
      toggleIssueDetailsModal(true);
      dispatch(getIssue(code));
    } else if (showIssueDetails) {
      toggleIssueDetailsModal(false);
    }
  }, [location.search]);

  //  Watching location pathname changes
  useEffect(() => {
    setIsNestedRoute(location?.pathname !== getParentPath(location?.pathname));
  }, [location?.pathname]);


  useEffect(() => {
    if (updatedPage !== null) {
      const newIndex = (updatedPage === 0) ? updatedPage : issues.length - 1;
      onIssueClicked(issues?.[newIndex]?.code, newIndex);
      setUpdatedPage(null);
    }
  }, [issues]);

  //  Component did mount
  useEffect(() => {
    didMountRef.current = true;
    dispatch(onShowGRCDetails(false));
  }, []);

  useEffect(() => {
    dispatch(getIssueModalOptions(null, issueType, userType));
  }, [getIssueModalOptions])

  //  Watching scan validation state
  useEffect(() => {
    // Users that can impersonate won't be redirected
    const canImpersonate = ability.can(CREATE_ACTION, PRIVILEGE_SOURCES.MULTIPLE.IMPERSONATE);
    if (isAuthorized || isAuthorized === null || canImpersonate) { return; }
    const message = scanStatus === scanStatusMap.NOT_REQUESTED_SCAN ? 'Request a scan to unlock your Issue Manager' : '';
    const title = 'Issue Manager';
    const state = { message, title, redirected: true };
    history.replace({ pathname: '/dashboard', state });
  }, [isAuthorized]);

  // Watching selected solution to change back label
  useEffect(() => {
    if (!isNestedRoute) { return; }
    const label = isGroupSubroute ? 'Go back to groups' : 'Go back to dashboard';
    const backPath = isGroupSubroute ? '/groups-manager' : '/dashboard';
    dispatch(setBackLink(label, true, backPath, true))
  }, [selectedSubTabValue, selectedSubTabLabel, isNestedRoute, isGroupSubroute]);

  //  Function to handle roll up legends click
  const handleRollupClick = (filter, widgetType) => {
    const filterLabel = widgetType === rollUpWidgetsIds.STATUS ? 'status' : 'severity';
    const label = widgetType === rollUpWidgetsIds.STATUS ? filter?.label : `Open, ${filter?.label}`
    dispatch(setIssuesFilter(filterLabel, filter?.id, label, filterTypes.multiple, null, false, issueType));
  };

  // Function to handle scroll changes
  const handleScroll = () => {
    const scrollPosition = scrollableContainerRef?.current?.scrollTop;
    const height = parseInt(getCurrentAccumulatedHeight(1)?.replace("px", ""));
    if (!rollupCollapsed && scrollPosition - height > 0) {
      setRollupCollapsed(true);
    } else if (rollupCollapsed && scrollPosition - height < 0) {
      setRollupCollapsed(false);
    }
  }

  const onIssueClicked = (code, index) => {
    if (!code || code === null) return;
    history.push({
      search: getUpdatedSearchParams(location?.search, ['details', 'selectedIssue'], `&details&selectedIssue=${code}`),
      state: { index, maxIndex: issues?.length - 1 }
    })
  }

  const updateIssue = (newIndex, newPage) => {
    if (newPage) {
      setUpdatedPage(newIndex)
      return;
    }
    onIssueClicked(issues?.[newIndex]?.code, newIndex);
  }

  // Check if member view is active
  useEffect(() => {
    if (isMemberView && activeImpersonation && location?.pathname === '/issues') {
      history.push('/dashboard/issues')
    }
  }, [location?.pathname, isMemberView, activeImpersonation]);

  //  Function to render modal
  const renderIssueDetailsModal = () => {
    if (!showIssueDetails) { return null; }
    return (
      <IssueDetails
        toggleModal={toggleIssueDetailsModal}
        expandInitially={shouldExpandModal}
        updateIssue={updateIssue}
      />
    );
  };

  //  Function to render issues title
  const renderIssueManagerTitle = () => {
    return (
      <div className="issue-manager-title-section">
        <div className="issue-manager-title">
          All your issues
        </div>
      </div>
    );
  };

  // Function to render Rollup widgets
  const renderRollupWidgets = () => {
    const isFreemium = freemiumStatus !== 0
    return (freemiumStatus === 0 || canImpersonate || renderSampleData) && (
      <RollupWidget
        sm={12}
        lg={6}
        sumAll={true}
        allowAllCompanies
        company_id={filters?.company_id}
        containerClassName={`rollup-container issue-rollup-container ${!rollupCollapsed ? 'expanded-rollup' : ''}`}
        widgetOrder={[rollUpWidgetsIds.CRITICALITY, rollUpWidgetsIds.STATUS]}
        cardClassName="issue-rollup-card h-100"
        handleRollupClick={handleRollupClick}
        solution_id={selectedSubTabValue}
        collapsed={rollupCollapsed}
        issueType={issueTypes.VULNERABILITIES}
        ariaLabel="Issues breakdown by criticality and Rollup"
        criticalityBar={true}
        customEmptyLabel={getScanRollupEmptyLabel(scanStatus, isFreemium)}
        customEmptyLabelClassName={getScanRollupClassName(scanStatus, isFreemium)}
      />
    )
  }

  // Function to render Trend by Criticality chart
  const renderCriticalityChart = () => {
    if (rollupCollapsed) return null;
    return (
      <Col sm={12} lg={6} className="mb-2">
        <Card className="grc-trend-chart-card mb-0">
          <CardBody>
            <TrendChartWrapper
              isSummary={false}
              title="Trend by Criticality"
              objects="issues"
              pointsColorConfig={issuesCriticalityConfigColor}
              labelColorConfig={criticalityColorsByLabel}
              pointsCountConfig={tendencyChartPointsByTimeUnit}
              apiKey={IssueAPI.keys.getVulnerabilityTrend}
              apiFunction={IssueAPI.handlers.getVulnerabilityTrend}
              emptyTitle='All quiet so far :)'
              emptySubtitle=''
              useLocalTimeFilter={true}
            />
          </CardBody>
        </Card>
      </Col>
    )
  };

  const renderTopCharts = () => {
    // Only for normal view
    if(issueType === issueTypes.GROUP_INDIVIDUAL) return null;
    return (
      <div
        className='issue-manager-rollup bg-dark sticky-element d-flex flex-wrap'
        id={issuesStickyDivsIds[0]}
        ref={refs[0]}
        style={{ top: getCurrentAccumulatedHeight(0) }}
      >
        {renderRollupWidgets()}
        {renderCriticalityChart()}
      </div>
    )
  }

  //  Rendering
  return (
    <div aria-label={`${companyName ? companyName : ''} Issues Page`} role="main" tabIndex="0">
      <Dashboard
        title="Issues"
        containerClassName="dashboard mx-2 px-0 py-4 text-normal-style page-content contained-view-width"
      >
        <MainScrollableContainer
          additionalClassName="issue-manager"
          scrollableRef={scrollableContainerRef}
          onScroll={handleScroll}
        >
          {renderIssueDetailsModal()}
          <div className="issue-manager-header">
            {renderIssueManagerTitle()}
          </div>
          {renderTopCharts()}
          <Row className="no-gutters">
            {(freemiumStatus === 0 || canImpersonate || renderSampleData) ?
              <IssuesList
                refs={refs}
                scrollableRef={scrollableContainerRef}
                getCurrentAccumulatedHeight={getCurrentAccumulatedHeight}
                showIssueDetails={showIssueDetails}
                collapsed={rollupCollapsed}
                onIssueClicked={onIssueClicked}
              /> : <IssueDemoPreview />
            }
          </Row>
        </MainScrollableContainer>
      </Dashboard>
    </div>
  )
};

export default IssueManager;
