import React, { useState, useEffect } from 'react'
import ReactApexChart from 'react-apexcharts';
import { getDate } from '../../../../utils/helpers';
import { getChartXAxisMaxValue, getChartXAxisMinValue, getXAxisAmountOfTicks } from '../../../../utils/timeChartHelpers';
import LoadingContent from '../../../layout/LoadingContent';
import InfoTooltip from '../../tooltips/InfoTooltip';
import TendencyChartControls from './TendencyChartControls'
const deafultColorsConfig = ["#fff"]
const TendencyChart = (props) => {
    const {
        isSummary = false, // Flag to know if is the simple version of the chart (useful to hide some components)
        isLoading,
        errorMessage,
        retryRequest,
        filters,
        setFilters,
        height = '200px',
        width = "550px",
        series: customSeries = [{ name: '', data: '' }],
        colorsConfig = deafultColorsConfig,
        selectedTimeUnit,
        currentPeriodValue,
        totalPointsConfig,
        renderCustomTooltip,
        chartTitle,
        showEmptyState = false,
        showTimeIntervalDataEmpty = false,
        emptyTitle = "All quiet",
        emptySubtitle = "No info to report so far :)",
    } = props;

    // Component state
    const [options, setOptions] = useState(null);
    const [series, setSeries] = useState(null);
    const [xAxisAnnotations, setXAxisAnnotations] = useState([])

    const getChartConfig = () => {
        return {
            zoom: { enabled: false },
            toolbar: { show: false },
        }
    }

    const getStrokeConfig = () => {
        return {
            width: 2,
            curve: 'straight',
        }
    }

    const getFillConfig = () => {
        return {
            colors: ['transparent'],
            type: 'solid',
            gradient: {
                type: 'vertical',
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
            }
        }
    }

    const getMarkersConfig = () => {
        return {
            size: 5,
            shape: 'circle',
            strokeWidth: 0,
            radius: 0,
            hover: {
                size: undefined,
                sizeOffset: 3.5
            },
        }
    }

    const getTimeUnitAnnotation = () => {
        return {
            x: isSummary ? 10 : 20,
            y: isSummary ? 165 : 195,
            yAxisIndex: 0,
            seriesIndex: 0,
            text: `${selectedTimeUnit}s`,
            textAnchor: 'middle',
            foreColor: "#605E92",
            fontSize: '11px',
            fontFamily: 'Open Sans',
            fontWeight: 400,
            backgroundColor: 'transparent',
            borderColor: '#c2c2c2',
            borderRadius: 0,
            borderWidth: 0,
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 2,
            paddingBottom: 2,
        }

    }

    const addXAxisAnnotations = (annotations) => {
        return annotations.map(({ label, value, color, offset }) => {
            return ({
                x: value,
                strokeDashArray: 6,
                borderColor: color,
                label: {
                    borderColor: 'transparent',
                    style: {
                        color: color,
                        background: 'transparent',
                        fontSize: "10px",
                        fontFamily: 'Open Sans',
                        cssClass: 'apexcharts-yaxis-annotation-label',
                    },
                    offsetX: -15,
                    offsetY: -128 - (offset || 0),
                    text: label,
                    textAnchor: 'start',
                    position: 'left',
                }
            })
        });
    }

    const getAnnotationsConfig = () => {
        return {
            position: 'back',
            yaxis: [],
            xaxis: addXAxisAnnotations(xAxisAnnotations),
            points: [],
            texts: [
                getTimeUnitAnnotation()
            ],
        }
    }

    const formatXAxisLabels = function (value) {
        return value < 0 ? '-' : parseInt(value)
    }


    //  Function to render normal point tooltip
    const renderNormalPointTooltip = (start_date, end_date) => {
        return `${getDate(start_date)}-${getDate(end_date)}`;
    };

    const formatXAxisTooltip = function (val, opts) {
        const { seriesIndex, dataPointIndex, w } = opts;
        const start_date = w.config.series[seriesIndex].data[dataPointIndex].start_date;
        const end_date = w.config.series[seriesIndex].data[dataPointIndex].end_date;
        return renderNormalPointTooltip(start_date, end_date);
    }
    const getXAxisConfig = () => {
        return {
            type: 'category',
            tickAmount: getXAxisAmountOfTicks(series),
            tickPlacement: 'between',
            axisBorder: {
                show: true,
                color: '#605E92',
                height: 1,
                width: '100%',
                offsetX: -3,
                offsetY: 0,
            },
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#605E92',
                height: 9,
                offsetX: 0,
                offsetY: 0,
            },
            labels: {
                formatter: formatXAxisLabels
            },
            tooltip: {
                enabled: true,
                formatter: formatXAxisTooltip,
                followCursor: true,
                offsetY: -185,
            },
            min: getChartXAxisMinValue(series),
            max: getChartXAxisMaxValue(series),
        }
    }

    const getYAxisConfig = () => {
        return {
            tickAmount: 5,
            min: 0
        }
    }

    const getTooltipConfig = () => {
        return {
            theme: false,
            shared: true,
            intersect: false,
            enabledOnSeries: [0],
            custom: renderCustomTooltip,
        }
    }

    const getResponsiveBreakpoints = () => {
        return [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        toolbar:
                        {
                            show: !1
                        }
                    },
                    legend: {
                        show: !1
                    }
                },
            },
        ]
    }

    const getCurrentPeriodAnnotationConfig = () => {
        return {
            label: `this ${selectedTimeUnit}`,
            value: currentPeriodValue,
            color: '#A6B0CF',
            offset: isSummary ? -20 : 0,
        }
    }

    // Returns options config for chart
    const getOptionsConfig = () => {
        return {
            chart: getChartConfig(),
            dataLabels: { enabled: false },
            stroke: getStrokeConfig(),
            colors: colorsConfig,
            fill: getFillConfig(),
            markers: getMarkersConfig(),
            annotations: getAnnotationsConfig(),
            xaxis: getXAxisConfig(),
            yaxis: getYAxisConfig(),
            legend: {
                show: false
            },
            tooltip: getTooltipConfig(),
            responsive: getResponsiveBreakpoints(),
        }
    }
    //Watch changes in color config and series
    useEffect(() => {
        const optionsConfig = getOptionsConfig();
        setOptions(optionsConfig)
    }, [colorsConfig, series])

    useEffect(() => {
        setSeries(customSeries)
    }, [customSeries]);

    // Watch changes in selectedTimeUnit and currentPeriodValue
    useEffect(() => {
        setXAxisAnnotations([
            getCurrentPeriodAnnotationConfig()
        ])
    }, [selectedTimeUnit, currentPeriodValue])


    // Function to render chart
    const renderChart = () => {
        const loadingContentStyle = { minHeight: "216px" };
        const wrapperStyle = { maxHeight: "200px" }
        const extraClassName = showEmptyState || showTimeIntervalDataEmpty ? 'preview-filter' : '';
        return (
            <LoadingContent
                isLoading={isLoading}
                errorMessage={errorMessage}
                errorButtonCallback={retryRequest}
                iconType="solidIcon"
                errorStyle={loadingContentStyle}
                loadingStyle={loadingContentStyle}
            >
                {(series && options) && (
                    <div className={`w-100 mt-3 d-flex ${extraClassName}`} style={wrapperStyle}>
                        <div className='mx-auto'>
                            <ReactApexChart
                                options={options}
                                series={series}
                                type="area"
                                height={height}
                                width={width}
                            />
                        </div>
                    </div>
                )}
            </LoadingContent>
        )
    }

    // Function to render buttons that control range and time units
    const renderControls = () => {
        const extraClassName = showEmptyState || showTimeIntervalDataEmpty ? 'preview-filter' : '';
        return (
            <div className={`ml-auto d-inline-block ${extraClassName}`}>
                <TendencyChartControls
                    isSummary={isSummary}
                    filters={filters}
                    setFilters={setFilters}
                    totalPointsConfig={totalPointsConfig}
                />
            </div>
        )
    }

    const renderTitle = () => {
        if (!chartTitle) return null;
        return (
            <div className="d-inline-flex align-items-center mr-2">
                <InfoTooltip
                    label={chartTitle}
                    info={null}
                    wrapperClassName="dashboard-widget-title"
                    id={`grc-criticality-trend-tooltip`}
                    showTooltip={false}
                />
            </div>
        )
    }

    const renderEmptyStateMessage = () => {
        if (isLoading) return null;
        if (!showEmptyState && !showTimeIntervalDataEmpty) return null;

        return showEmptyState ? (
            <div className='tendency-chart-empty-state overlay'>
                <div>
                    <div className='empty-title text-center'>{emptyTitle}</div>
                    <div className='empty-subtitle text-center'>{emptySubtitle}</div>
                </div>
            </div>
        ) :
        (
            <div className='tendency-chart-empty-state overlay'>
                <div>
                    <div className='empty-data-subtitle text-center'>{`Selected time interval doesn't have data to display`}</div>
                    <div className='empty-data-title text-center'>{'Choose another time interval'}</div>
                </div>
            </div>
        )
    }

    return (
        <>
            {renderEmptyStateMessage()}
            <div className='tendency-chart d-flex w-100 flex-column'>
                <div className='d-flex'>
                    {renderTitle()}
                    {renderControls()}
                </div>
                {renderChart()}
            </div>
        </>
    )
}

export default TendencyChart;
