import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Link,
  useLocation,
  useHistory,
} from 'react-router-dom';
import FormCard from '../layout/FormCard';
import CustomForm from './../CustomForm/CustomForm';
import FreemiumInformationWrapper from '../../containers/FreemiumInformationWrapper';
import { Form } from 'react-final-form';
import { setAlert } from "../../actions/alerts";
import amplitude from 'amplitude-js';
import {
  validateEmail,
  validateEmptyTextField,
  validatePassword,
  validateNames,
  validateCompanyName,
} from '../../utils/validations';
import API from '../../api';
import { REGISTER_FREEMIUM_FAILURE } from '../../actions/types';
import { buildUsertoRegister, handleRegistersErrors } from '../../utils/authHelper';
import { changeLayout } from '../../actions/layout';
import { trackEvent } from '../../utils/gaTracker';
import { errorActionCreator } from '../../actions/action-creators/errors';
import { freemiumLoginForm } from '../../constants/formTemplates';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../hooks/useCustomMutation';

//  Component default values
const formDefaulValues = {
  defaultFirstName: '',
  defaultLastName: '',
  defaultEmail: '',
  defaultCompany: '',
};

//  Component
const FreemiumLogin = (props) => {
  //  Initializing API
  const { AuthAPI } = API;

  //  Components hooks
  const { dispatch } = useMemoizedDispatch();
  const location = useLocation();
  const history = useHistory();
  const {
    mutate: registerUser,
    error: registerError,
    data: registerData,
    isLoading: registerLoading,
  } = useCustomMutation(
    (params) => AuthAPI.handlers.register(params),
    AuthAPI.invalidators.register,
  );

  //  Watching redux store
  const error = useSelector((state) => state.auth.error);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const mfaConfig = useSelector((state) => state.auth.mfaConfig);

  //  Component state
  const [defaultValues, setDefaultValues] = useState(formDefaulValues);
  const [populateFinished, setPopulateFinished] = useState(false);
  const [leadSource, setLeadSource] = useState('None');

  //  Watching register error
  useEffect(() => {
    if (!registerError) return;
    const message = registerError?.error?.data?.errorMessage || 'Error registering user.';
    dispatch(errorActionCreator(REGISTER_FREEMIUM_FAILURE, { message }));
    handleRegistersErrors(error, null, dispatch);
  }, [registerError]);

  //  Watching register success
  useEffect(() => {
    if (!registerData) { return; }
    trackEvent("register", "qualified_leads", "freemium_register");
    //  Sending needed info to register
    const { user = {}, isOwner = false } = registerData?.data || {};
    const { public_id, email } = user;
    history.replace({
      pathname: '/register-success',
      state: { public_id, email, is_owner: isOwner },
    })
  }, [registerData]);

  useEffect(() => {
    amplitude.getInstance().logEvent('Page View - Sign Up');
    let s = document.createElement('script');
    const options = { primaryKey: "name" };
    s.src = "https://metadata-static-files.sfo2.cdn.digitaloceanspaces.com/pixel/lp.js";
    s.async = true;
    s.onload = function () {
      window.Metadata.pixel.init(options);
    };
    document.head.appendChild(s);

    return () => {
      document.head.removeChild(s);
    }
  }, []);

  useEffect(() => {
    dispatch(changeLayout('horizontal'));
  }, [changeLayout]);

  useEffect(() => {
    if (isAuthenticated === null) { return }
    const params = new URLSearchParams(location?.search);
    const newLeadSource = params.get('utm_campaign') || params.get('utm_source') || document.referrer || 'None';
    setDefaultValues({
      defaultFirstName: params.get('First Name') || '',
      defaultLastName: params.get('Last Name') || '',
      defaultEmail: params.get('email') || '',
      defaultCompany: params.get('Company') || '',
    });
    setPopulateFinished(true);
    setLeadSource(newLeadSource);
  }, [isAuthenticated])

  if (location.pathname === '/freemium') {
    return <Redirect to="/my-cyvatar" />
  }

  if (isAuthenticated) {
    return <Redirect to="/" />
  }

  function validate(formValues) {
    const { firstName, lastName, company, email, password } = formValues;
    const errors = {};

    if (validateNames('first_name', firstName)) {
      errors.firstName = validateNames('first_name', firstName);
    }

    if (validateNames('last_name', lastName)) {
      errors.lastName = validateNames('last_name', lastName);
    }

    if (validateEmail(email)) {
      errors.email = validateEmail(email);
    }

    if (validatePassword(password)) {
      errors.password = validatePassword(password);
    }

    if (validateEmptyTextField(company) || validateCompanyName(company)) {
      errors.company = validateEmptyTextField(company) || validateCompanyName(company);
    }
    return errors;
  }

  function setRegisterPayload(values) {
    const { firstName, lastName, company, email, password } = values;
    return {
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
      },
      company: {
        name: company,
        company_email: email,
        freemium: 2
      },
      leadSource
    }
  }

  //  Function to render form
  const renderForm = (params) => {
    //  Parsing params
    const { handleSubmit, submitting, invalid } = params;
    //  Returning form
    return (
      <CustomForm
        formTemplate={freemiumLoginForm}
        defaultValues={defaultValues}
        onSubmit={(event) => {
          if (invalid) setAlert('One or more fields have an error. Please check and try again', 'danger');
          handleSubmit(event);
        }}
      >
        <div style={{ marginBottom: "15px" }} >
          <button className="btn btn-light text-dark btn-block light-freemium-button" type="submit">
            {(submitting || registerLoading) ? 'PROCESSING...' : 'Create Account'}
          </button>
        </div>
        <div className='text-center freemium-terms'>
          By signing up, you agree to the <a href={process.env.REACT_APP_TERMS_SERVICE} target='_blanck' rel="noopener noreferrer" aria-label='Open Terms of Service'>Terms of Service</a> and <a href='https://cyvatar.ai/privacy-policy/' target='_blanck' rel="noopener noreferrer" aria-label='Open Privacy Policy'>Privacy Policy</a>.
          <br />
          <div className='already-member'>
            Already a member? <Link to='/login'>Sign in</Link>
          </div>
        </div>
      </CustomForm>
    );
  };

  //  Function to submit info
  const onSubmit = (values) => {
    const payloadToStore = setRegisterPayload(values);
    const userPayload = buildUsertoRegister(payloadToStore, false, true);
    registerUser(userPayload);
  };

  return (
    <div className='freemium-login-scrollable-container'>
      <FreemiumInformationWrapper
        readyToRender={populateFinished}
        customInfoClass="freemium-signup-information"
      >
        {!mfaConfig?.token &&
          <FormCard
            customClassName="freemium-form"
            error={error}
            customWidth={'100%'}
          >
            <div>
              <Form
                onSubmit={onSubmit}
                validate={validate}
                shouldValidate={true}
                render={renderForm}
              />
            </div>
          </FormCard>
        }
      </FreemiumInformationWrapper>
    </div>
  );
};

export default FreemiumLogin;
