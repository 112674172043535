import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Media, Button } from "reactstrap";

const CollapseButton = (props) => {
  //  Parsing props
  const {
    color = "light-gray",
    isCollapsed,
    toggleCollapsed,
    itemClassName = '',
  } = props;

  //  Rendering
  return (
    <Button
        color={color}
        className={`btn-collapse rounded-circle ${itemClassName}`}
        outline
        onClick={toggleCollapsed}
    >
        <i className={`btn-collapse bx bx-chevron-up ${ isCollapsed ? 'bx-rotate-180': ''}`} ></i>
    </Button>
  )
};

export default CollapseButton;
