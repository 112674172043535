import React from 'react';
import { Badge } from 'reactstrap';
import criticalityInfo from '../../constants/criticality';

const CriticalityBadge = (props) => {
    const {
        severity,
        className,
    } = props;

    const getCriticalityClassName = () => {
        return `outline-${criticalityInfo[severity]?.className}`
    }
    return (
        <Badge
            color={getCriticalityClassName()}
            className={`criticality-badge ${className}`}
        >
            {criticalityInfo[severity]?.label}
        </Badge>
    )
}

export default CriticalityBadge;
