import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import GrcIssuesDetails from './GrcIssuesDetails';
import IssueDetails from '../issue-manager/IssueDetails';
import { DropdownMenu, DropdownToggle, Button } from 'reactstrap';
import { getIssue, onShowGRCDetails, onUpdateIndexDetailsModal } from '../../actions/issues';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useURLSearchParams from '../../hooks/useURLSearchParams';
import amplitude from 'amplitude-js';


const GrcNavigationDropdown = () => {
    //  Watching redux store
    const showGRCDetails = useSelector((state) => state.grcIssues.showDetailsModal);

    // Component State
    const [isOpen, setIsOpen] = useState(false);
    const [showIssueDetails, setShowIssueDetails] = useState(false);

    // Component Hooks
    const location = useLocation();
    const { dispatch } = useMemoizedDispatch();
    const { params } = useURLSearchParams({ paramsEntries: ['selectedIssue', 'details', 'task'] });

    //	Component variables
    const isGRCIssue = useMemo(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.has('task');
    }, [location.search]);

    //  Function to open create modal
    const openCreateModal = () => {
        dispatch(onShowGRCDetails(true));
    };
    

    //  Watching params changes
    useEffect(() => {
        if (!params) { return; }
        const { selectedIssue, details, task } = params;
        if (task === null || task === undefined) { return; }
        if (details === null || details === undefined || !selectedIssue) { return; }
        openCreateModal();
    }, [params]);


    // Request parent issue data 
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const code = queryParams.get("selectedIssue");
        if (!isGRCIssue && code && queryParams.has("parent")) {
            dispatch(getIssue(code));
            if (!showIssueDetails) setShowIssueDetails(true);
        }
    }, [location?.search]);

    const onToggle = () => {
        setIsOpen(!isOpen)
    }

    const renderDropdownToggle = () => {
        const className = `btn btn-grc-toggle ${location?.pathname?.includes('/task-manager') ? 'active' : ''}`
        return (
            <DropdownToggle
                className={className}
                tag="button"
                role="button"
                aria-label="GRC, Governance, risk and compliance menu"
            >
                Task Manager
            </DropdownToggle>
        )
    }

    const renderDismissButton = () => {
        return (
            <div className="d-inline-block ml-3">
                <Button
                    color="link"
                    className="close"
                    onClick={onToggle}
                    aria-label="Close GRC menu"
                >
                    <i className='bx bx-x' />
                </Button>

            </div>
        )
    }

    const renderMenuHeader = () => {
        return (
            <div className="grc-menu-header header-item dropdown-item">
                Track all your team's tasks
                {renderDismissButton()}
            </div>
        )
    }

    const onCreateClick = () => {
        amplitude.getInstance().logEvent('MYCYSO','My CySO View - Task Manager Header - Create Task Manager Issue');
        openCreateModal();
    };

    const updateIssue = (newIndex, isNewPage) => {
        dispatch(onUpdateIndexDetailsModal(newIndex, isNewPage))
    }

    const renderMenu = () => {
        const viewGRCRoute = "/dashboard/task-manager";
        const viewGRCSelected = location?.pathname === viewGRCRoute ? 'selected' : ''
        return (
            <DropdownMenu className="grc-dropdown-menu pb-0" tabIndex="0"
                aria-label="Governance risk and compliance drop menu">
                {renderMenuHeader()}
                <div className="dropdown-divider mb-0"></div>
                <Button
                    className="dropdown-item"
                    onClick={onCreateClick}
                    aria-label="Create GRC Issue"
                >
                    Create new task
                </Button>
                <Link
                    to={viewGRCRoute}
                    className={`dropdown-item ${viewGRCSelected}`}
                    onClick={onToggle}
                    aria-label="View GRC Issues"
                >
                    View tasks
                </Link>
            </DropdownMenu>
        )
    }

    //  Function to render issues details modal
    const renderGRCDetailModal = () => {
        if (showGRCDetails && !params?.selectedIssue) { return <GrcIssuesDetails code={null} /> }
        if (!showGRCDetails || !isGRCIssue) { return null; }
        return (
            <GrcIssuesDetails
                code={params?.selectedIssue}
                updateIndex={updateIssue}
                closeSubTaskModal={() => setShowIssueDetails(!showIssueDetails)}
            />
        );
    };

    const renderIssuesDetailModal = () => {
        if (isGRCIssue || !showIssueDetails) return null;
        return (
            <IssueDetails
                toggleModal={() => setShowIssueDetails(!showIssueDetails)}
                expandInitially={true}
                comesFromSubtask={true}
            />
        )
    }

    // const renderDropdownMenu = () => {
    //     return (
    //         <Dropdown
    //             isOpen={isOpen}
    //             toggle={onToggle}
    //             className="grc-dropdown d-inline-block"
    //         >
    //             {renderDropdownToggle()}
    //             {renderMenu()}
    //         </Dropdown>
    //     )
    // }

    //  Rendering
    return (location?.pathname?.includes('/dashboard') || location?.pathname === "/issues") && (
        <>
            {/* {renderDropdownMenu()} */}
            {renderGRCDetailModal()}
            {renderIssuesDetailModal()}
        </>
    )
}

export default GrcNavigationDropdown
