import Joi from 'joi';
import { getSafeArray } from './helpers';

//  Schemas
const ruleSetSchema = Joi.object({
  id: Joi.number().optional(),
  name: Joi.string().min(5).required().error(new Error('Missing rule set name. ')),
  tags: Joi.array(),
  tags_searchable: Joi.array(),
  all_members: Joi.boolean()
});

const ruleDraftSchema = Joi.object({
  id: Joi.number().optional(),
  action_id: Joi.number().required().error(new Error('Set action for all rules. ')),
  partner_id: Joi.number().required().error(new Error('Set partner for all rules. ')),
  repeat: Joi.string().allow(null).optional(),
  schedule: Joi.string().optional(),
  run_now: Joi.boolean().optional(),
  status: Joi.number().optional(),
});

//  Schema for validating rule set draft
const ruleSetDraftSchema = Joi.object({
  rule_set: ruleSetSchema,
  companies: Joi.array(),
  rules: Joi.array().items(ruleDraftSchema).min(1).error(new Error('Need at least one complete rule. ')),
  publish: Joi.boolean().invalid(true).error(new Error('Not able to publish rule set. ')),
});

const ruleToPublishSchema = Joi.object({
  id: Joi.number().optional(),
  action_id: Joi.number().required().error(new Error('Set action for all rules. ')),
  partner_id: Joi.number().required().error(new Error('Set partner for all rules. ')),
  repeat: Joi.string().allow(null).required().error(new Error('Need to create a repeat rule for all rules. ')),
  schedule: Joi.string().required().error(new Error('All rules must have at least an start time. ')),
  run_now: Joi.boolean().required(),
  status: Joi.number().optional(),
})

//  Schema for validating rule set to publisj
const ruleSetToPublishSchema = Joi.object({
  rule_set: ruleSetSchema,
  publish: Joi.boolean().invalid(false).error(new Error('Not able to save draft. ')),
  companies: Joi.array().when('rule_set.all_members', { is: false, then: Joi.array().min(1) }).error(new Error('Select at least one company. ')),
  rules: Joi.array().items(ruleToPublishSchema).min(1).error(new Error('Need at least one complete rule. ')),
})

//  Schema for GRC comments
const grcCommentsSchema = Joi.object({
  list: Joi.array().required()
});

//  GRC issue base schema
const grcIssueBaseSchema = {
  name: Joi.string().min(1).required().label('Name should not be empty.'),
  severity: Joi.number().integer().optional().allow(null),
  priority: Joi.number().integer().required().label('Need to select a priority.'),
  assigned_to: Joi.number().integer().optional().allow(null),
  description: Joi.string().optional().allow(null, ''),
  status: Joi.number().integer().optional().allow(null),
  due_date: Joi.date().optional().allow(null, ''),
  comments: grcCommentsSchema.allow(null),
  partner_unique_id: Joi.string().optional().allow(null),
  extra_fields: Joi.object().optional().allow(null),
  partner: Joi.object().optional().allow(null),
}

//  Schema for GRC issues
const grcIssueSchema = Joi.object({ ...grcIssueBaseSchema });

//  Schema for GRC create issue
const grcIssueCreateSchema = Joi.object({
  ...grcIssueBaseSchema,
  company_id: Joi.number().integer().required().label('Need to select a company.'),
  parent_issue_id: Joi.number().integer().optional().allow(null),
})

//  Validators
export const validateRuleSetDraft = (ruleSetDraft) => {
  return ruleSetDraftSchema.validate(ruleSetDraft);
};

export const validateRuleSetToPublish = (ruleSet) => {
  return ruleSetToPublishSchema.validate(ruleSet);
};

export const validateGRCIssue = (grcIssue, isUpdating = false) => {
  if (!isUpdating) { return grcIssueCreateSchema.validate(grcIssue); }
  return grcIssueSchema.validate(grcIssue);
};

export const getValidationKeyError = (error, key) => {
  if(!error || !key || error?.key !== key) return null;
  return error
}

//  Helpers
export const getValidationError = (error) => {
  //  Getting details
  const { details = [] } = error;
  const safeDetails = getSafeArray(details);

  //  Getting first detail
  const detail = safeDetails[0];
  if (!detail) { return 'Validation Error'; }

  //  Returning error object
  return {
    message: detail?.context?.label || 'Validation Error',
    key: detail?.context?.key
  };
};
