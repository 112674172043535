import React from 'react';

const ImpersonationEmptyView = (props) => {
  //  Parsing props
  const { viewName } = props;

  //  Rendering
  return (
    <div className="dashboard-empty-container">
      <div className="dashboard-empty-message">
        {`Pick a company to see their ${viewName}`}
      </div>
    </div>
  );
};

export default ImpersonationEmptyView;
