import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Row, Button, Col, Card, CardBody, Table } from 'reactstrap';
import Pagination from '../layout/CustomPagination';
import SearchBar from '../layout/filters/SearchBar';
import { UilAngleUp, UilAngleDown } from '@iconscout/react-unicons'
import CallbackSelectField from '../layout/fields/SelectFieldCallback';
import ActiveFiltersList from '../layout/filters/ActiveFiltersList';
import filterTypes from '../../constants/filterTypes';
import FilterButton from './filters/FilterButton';
import useStickyGroupHeight from '../../hooks/useStickyGroupHeight';
import { commonTableStickyDivsIds } from '../../constants/common';
import { getAccumulatedHeights } from '../../utils/scrollHelpers';
import MainScrollableContainer from './MainScrollableContainer';
import LoadingContent from './LoadingContent';
import { isValidFunction } from '../../utils/helpers';
/* *
 * TableList: table component with filters and a search box that is sortable
 **/
const TableList = (props) => {

    const tableListContainerRef = useRef(null);
    const tableRef = useRef(null);
    const scrollableContainerRef = useRef(null);


    // Component state
    const [currentPage, setCurrentPage] = useState(0);
    const [divTopPositions, setDivTopPositions] = useState([]);
    // Component hooks
    const { refs, getCurrentAccumulatedHeight } = useStickyGroupHeight({ elementsIds: commonTableStickyDivsIds });

    const {
        isLoading,
        errorMessage,
        customClassName,
        containerClassName="",
        tableCardClassName="",
        columns,
        data: { list, shouldReload, filters, pageCount, activeFilters, total, searchTerm, sortField},
        perPage = 20,
        fetchList,
        reloadList,
        setFilter,
        clearAllFilters,
        setSort,
        filtersComponentList,
        renderButton,
        activeFiltersDisplayInfo,
        searchPlaceholder,
        emptyTableMessage,
        extraParams,
        activeFilterListClassName,
        companyId,
        onRowClick,
        renderBulkMenu,
        selectedElements,
        allElementsSelected,
        renderTopComponent,
        topCollapsed = false,
        sidebarCollapsed = false,
        accumulatedHeightOffset = 0,
        onScroll,
        rowPrefix = 'custom-table-row-',
        renderCustomEmptyComponent,
        filterSectionXOffset = 0,
        middleSectionXOffset = 0,
        readOnlyRow= false,
        onUpdatePage,
        updatedPage
    } = props;


    //  Watching DOM changes to update header height, needed for bulk actions
    useEffect(() => {
        setDivTopPositions(getAccumulatedHeights(0,4, getCurrentAccumulatedHeight, accumulatedHeightOffset));
    }, [getCurrentAccumulatedHeight(4), allElementsSelected, selectedElements?.length, topCollapsed, sidebarCollapsed]);

    /**
     * Get config for retrieving list
     */
    const getConfig = (selectedPage = currentPage) => {
        return {
            ...extraParams,
            company_id: companyId,
            page: selectedPage + 1,
            per_page: perPage,
            filters,
            text_to_search: searchTerm,
            sort: sortField?.name ? {
                [sortField?.name]: sortField?.asc ? 'ASC' : 'DESC'
            }: null
        }
    }
    /**
     * Calls fetchList with the respective config for pagination, filtering and sorting
     **/
    const fetchItemsList = (selectedPage = currentPage, shouldRefetch = false) => {
        const config = getConfig(selectedPage);
        setCurrentPage(selectedPage);
        fetchList(config, shouldRefetch);
    }

    /**
     * Calls fetchItemsList when Try Again button is clicked
     **/
    const onTryAgainButtonClick = () => {
        fetchItemsList(getConfig(), true);
    }

    useEffect(() => {
        fetchItemsList(0, false);
    }, [perPage, filters, sortField, searchTerm, companyId]);


    useEffect(() => {
        if (updatedPage!==null && updatedPage!==currentPage) {
            fetchItemsList(updatedPage);
        }
    }, [updatedPage])

    useEffect(() => {
        if(shouldReload) {
            fetchItemsList(null, true);
            if(reloadList){
               reloadList(false)
            }
        }
    }, [shouldReload])

    const handleScroll = () => {
        if(!onScroll || typeof(onScroll) !== 'function') return;
        const topComponentHeight = divTopPositions[1];
        const params = {
            topComponentHeight,
            scrollableRef: scrollableContainerRef
        };
        onScroll(params);
    }

    /**
     * Renders filtersList array depending on type
     **/
    const renderFiltersList = () => {
        //TODO: still is wip
        return filtersComponentList.map((filter, index) => {
            if(filter?.renderCustomFilter && typeof(filter?.renderCustomFilter) === 'function') {
                return filter.renderCustomFilter(setFilter)
            }
            switch(filter.type) {
                case filterTypes.select: {
                    return (
                        <div className={filter.className} key={`filter-callback-${index}`}>
                            <CallbackSelectField
                                placeholder={filter.placeholder}
                                options={filter.options}
                                value={!_.isNil(filters[filter.fieldName]) && filter.options.length > 0 ?_.find(filter.options[0]?.options, { 'value': filters[filter.fieldName] }) : null}
                                callback={(value) => setFilter(filter.fieldName, value.value, value.label, filterTypes.single)}
                            />
                        </div>
                    )
                }
                case filterTypes.single:
                default :{
                    return (
                        <FilterButton
                            key={`filter-button-${index}`}
                            currentValue={filters[filter.fieldName]}
                            activeValue={filter.value}
                            setValueCallback={(value) =>{
                                setFilter(filter.fieldName, value, filter.label, filter?.type, null);
                            }}
                            className={filter.className}
                            customStyle={filter.style}
                            label={filter.label}
                        />
                    );
                }
            }
        });
    };

    const renderDisplayName = (column) => {
        return  column.renderCustomHeader && typeof(column.renderCustomHeader) === 'function' ? column.renderCustomHeader() : column.displayName;
    }

    const renderSortColumnHeader = (column) => {
        return (
            <Button
                color="link"
                className='btn-sort-arrows p-0 d-flex align-items-center'
                onClick={
                    () => {
                        setSort({
                            name: column.sortFieldName,
                            asc: column.sortFieldName !== sortField?.name ? true :!sortField?.asc
                        })
                    }
                }
            >
                {renderDisplayName(column)}
                <div className="ml-1">
                    <div className={`sort-arrow ${sortField?.name === column.sortFieldName && sortField?.asc && 'active'}`}>
                        <UilAngleUp />
                    </div>
                    <div className={`sort-arrow ${sortField?.name === column.sortFieldName && !sortField?.asc && 'active'}`}>
                        <UilAngleDown />
                    </div>
                </div>
            </Button>
        )
    }

    /**
     * Renders the table's headers,
     * if sortFieldName is null or undefined, then the column won't be sortable
     **/
    const renderHeaders = () => {
        const stickyDivIndex = 4;
        return columns.map( (column, index) => {
            return (
                <th
                    className="sticky-element"
                    style={{ top: divTopPositions[stickyDivIndex] }}
                    key={`${column.fieldName}-${index}`}
                >
                    <div className={column.headerClassNameWrapper}>
                    {!_.isNil(column.sortFieldName)? (
                        renderSortColumnHeader(column)
                    ): renderDisplayName(column)}
                    </div>
                </th>
            )
        })
    }

    const handleOnRowClick = (row,index) => { if (onRowClick) onRowClick(row,index) }

    /**
     * Render the table's rows,
     * for each row, it renders by default the respective content for column.fieldName
     * if a renderCustomComponent is given for a column, then this method will be called with row as a parameter
     **/
    const renderRows = () => {
        return list && list.map((row, index)=> {
            return (
                <tr
                    id={`${rowPrefix}${row?.id}`}
                    key={`row-${index}`}
                    onClick={() => (handleOnRowClick(row,index))}
                >
                    {columns.map((column, index) => {
                        return (
                            <td key={`column-${index}-${column.fieldName}`}>
                                <div className={column.classNameWrapper}>
                                    {column.renderCustomComponent ? (
                                        column.renderCustomComponent(row)
                                    ):row[column.fieldName]}
                                </div>
                            </td>
                        )
                    })}
                </tr>
            )
        })
    }

    const tryAgainButton = (
        <button
            className="btn btn-light btn-error d-inline-block"
            onClick={onTryAgainButtonClick}
        >
            Try again
        </button>
    );

    const onPageChange = ({ selected: selectedPage }) => {
        fetchItemsList(selectedPage);
        if(isValidFunction(onUpdatePage)) { onUpdatePage(selectedPage) }
    }

    // Function to render pagination
    const renderPagination = () => {
        return (
            <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                onPageChange={onPageChange}
            />
        )
    }

    const renderActiveFilters = () => {
        return (
            <ActiveFiltersList
                title={activeFilters?.length > 0 ? activeFiltersDisplayInfo?.labels?.filteringBy : activeFiltersDisplayInfo?.labels?.noFilters}
                list={activeFilters}
                total={{ number: total, label: `${total !== 1 ? activeFiltersDisplayInfo?.labels?.total?.plural || 'items': activeFiltersDisplayInfo?.labels?.total?.singular || 'item'}`}}
                setFilter={setFilter}
                clearAllFilters={clearAllFilters}
                className={activeFilterListClassName}
            />
        )
    }

    // Function to render top section above table
    const renderTopSection = () => {
        if(!renderTopComponent || typeof(renderTopComponent) !== 'function') return null;
        const stickyDivIndex = 0;
        const top = divTopPositions[stickyDivIndex];
        const params = {
            tableRef: tableListContainerRef,
            componentStyle: {
                top,
                width: `${tableListContainerRef?.current?.clientWidth}px`,
                left: 0,
            },
            scrollableRef: scrollableContainerRef,
            componentId: commonTableStickyDivsIds[stickyDivIndex],
            componentRef: refs[stickyDivIndex],
        }
        return renderTopComponent(params)
    }

    // Function to render middle section that contains buttons and active filters
    const renderMiddleSection = () => {
        const stickyDivIndex = 1;
        const style = {
            top: divTopPositions[stickyDivIndex],
            width: `${tableListContainerRef?.current?.clientWidth - middleSectionXOffset}px`,
            left: 0
        }
        return (
            <div
                id={commonTableStickyDivsIds[stickyDivIndex]}
                ref={refs[stickyDivIndex]}
                style={style}
                className="table-middle-container bg-dark d-flex sticky-element justify-content-end bg-dark pt-2"
            >
                <div className="flex-grow-1">
                    {renderActiveFilters()}
                </div>
                {renderButton && (
                    <div className="middle-buttons-container d-flex">
                        <div className="d-inline-block ml-auto">
                            {renderButton()}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    // Function to render filters and search bar
    const renderFilterSection = () => {
        const stickyDivIndex = 2;
        const style = {
            top: divTopPositions[stickyDivIndex],
            width: `${tableListContainerRef?.current?.clientWidth - filterSectionXOffset}px`,
            left: 0,
            zIndex: 200
        }
        return (
            <div
                id={commonTableStickyDivsIds[stickyDivIndex]}
                ref={refs[stickyDivIndex]}
                style={style}
                className="sticky-element bg-violet pt-2 px-3"
            >
                <Row className="mb-2 justify-content-between pl-1">
                    <Col sm="9"className="table-filters-container">
                        {filtersComponentList && renderFiltersList()}
                        <SearchBar
                            placeholder={searchPlaceholder}
                            value={searchTerm}
                            callback={(value) => setFilter('text_to_search', value, `Search: ${value}`, filterTypes.search )}
                        />
                    </Col>
                    <Col sm="3" className="table-pagination-container">
                        {renderPagination()}
                    </Col>
                </Row>
            </div>
        )
    }

    // Function to render bulk actions
    const renderBulkupActions = () => {
        if(!renderBulkMenu || typeof(renderBulkMenu) !== 'function') return null;
        const stickyDivIndex = 3;
        const top = divTopPositions[stickyDivIndex];
        const params = {
            tableRef: tableRef,
            bulkMenuId: commonTableStickyDivsIds[stickyDivIndex],
            bulkMenuRef: refs[stickyDivIndex],
            bulkMenuStyle: { top },
            scrollableRef: scrollableContainerRef
        }
        return renderBulkMenu(params)
    }

    // Function to render table headers
    const renderTableHeaders = () => {
        const stickyDivIndex = 4;
        return (
            <thead
                id={commonTableStickyDivsIds[stickyDivIndex]}
                ref={refs[stickyDivIndex]}
                style={{ top: divTopPositions[stickyDivIndex] }}
                className="sticky-element"
            >
                <tr
                    style={{ top: divTopPositions[stickyDivIndex] }}
                    className="sticky-element"
                >
                    {renderHeaders()}
                </tr>
            </thead>
        )
    }

    const renderLoadingContent = () => {
        if(!isLoading && !errorMessage) return null;
        return (
            <tr className='tr-disabled'>
                <td colSpan={columns?.length}>
                    <LoadingContent
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        errorButton={tryAgainButton}
                        errorClassName="table-error-message"
                        errorStyle={{ paddingTop: "5%"}}
                        loadingStyle={{ minHeight: "303px"}}
                    />
                </td>
            </tr>
        )
    }

    const getEmptyMessage = () => {
        const hasCreatedElements = _.isEmpty(searchTerm) && _.isEmpty(filters);
        const elementsLabel = activeFiltersDisplayInfo?.labels?.total?.plural;
        return hasCreatedElements ? (
            emptyTableMessage ||  `No ${ elementsLabel || 'items'} for now..`
        ):(
            `No ${elementsLabel || 'items'} found`
        )
    }

    const renderEmptyComponent = () => {
        const isCustom = renderCustomEmptyComponent && typeof(renderCustomEmptyComponent) === 'function';
        return !isLoading && !errorMessage && list?.length === 0 ? (
            <tr className='tr-disabled'>
                <td colSpan={columns?.length || 1}>
                    <div className="empty-table text-center">
                        {isCustom ? renderCustomEmptyComponent() : getEmptyMessage()}
                    </div>
                </td>
            </tr>
        ) : null;
    }

    // Function to render table
    const renderTable = () => {
        const pointerClassName = !readOnlyRow ? 'pointer' : 'pe-none';
        return (
            <div
                className="table-scrollable-container"
            >
                <Table className={`sticky-table table-centered table-hover ${pointerClassName} px-3 ${customClassName}`}>
                    {renderTableHeaders()}
                    <tbody>
                        {!isLoading && !errorMessage && list?.length > 0 &&
                            renderRows()
                        }
                        {renderEmptyComponent()}
                        {renderLoadingContent()}
                    </tbody>
                </Table>
            </div>
        )
    }

    const renderTableCard = () => {
        return (
            <Card className={`h-100 ${tableCardClassName}`}>
                <CardBody className="px-0">
                    {renderFilterSection()}
                    {renderBulkupActions()}
                    <div className="table-list">
                        <div className="table-responsive overflow-x-unset">
                            {renderTable()}
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }

    return (
        <MainScrollableContainer
            additionalClassName={containerClassName}
            scrollableRef={scrollableContainerRef}
            onScroll={handleScroll}
        >
            <div
                ref={tableListContainerRef}
                className="d-flex row no-gutters flex-wrap"
            >
                <div className="align-self-start"  style={{ marginBottom: "5px" }}>
                    {renderTopSection()}
                    <div
                        ref={tableRef}
                    >
                        {renderMiddleSection()}
                        {renderTableCard()}
                    </div>
                </div>
            </div>
        </MainScrollableContainer>
    )
}
TableList.propTypes = {
    /**
    * If true, a spinner will be rendedered.
    */
    isLoading:PropTypes.bool,
    /**
    * If it isn't null or undefinded, the error message will be displayed instead of the table contents
    */
    errorMessage:PropTypes.string,
    /**
    * Table wrapper className
    */
    customClassName:PropTypes.string,
    /**
    * Array of objects that contain info for rendering each column
    */
    columns: PropTypes.arrayOf(PropTypes.shape({
        /**
        * column's display name
        */
        displayName: PropTypes.string,
        /**
        * key for obtaining the respective column's data
        */
        fieldName:PropTypes.string,
        /**
        * field name by which the table data is going to be sorted
        */
        sortFieldName: PropTypes.string,
        /**
        * function for rendering the column's custom component
        */
        renderCustomComponent: PropTypes.func,
        /**
        * column's cell wrapper className
        */
        classNameWrapper: PropTypes.string,
        /**
        * column's header wrapper className
        */
       headerClassNameWrapper: PropTypes.string
    })),
    /**
    * Object containing the state of the list to be rendered, including
    * filters, active filters list, pageCount, total, searc term ...
    */
    data: PropTypes.shape({
        /**
        * Data to be rendered in table.
        */
        list: PropTypes.array,
        /**
        * If true, fetches the table's list again.
        */
        shouldReload: PropTypes.bool,
        /**
        * Contains the current value for each filter.
        */
        filters: PropTypes.object,
        /**
        * Contains the state of each active filter
        */
        activeFilters: PropTypes.arrayOf(PropTypes.shape({
            /**
             * label: string, display name of the active filter
            */
            label: PropTypes.string,
            /**
            * * filterName: string, field name of filter
            */
            filterName: PropTypes.string,
            /**
            * filterType: type of filter (ex: single, search, multiple...)
            */
            filterType: PropTypes.string,
            /**
            * value: the filter's actual value
            */
            value: PropTypes.any,
        })),
        /**
        * total number of items/rows to be displayed
        */
        searchTerm: PropTypes.string,
        /**
        * field name by which the table is going to be sorted
        */
        sortField: PropTypes.string,
        /**
        * total number of items/rows of the data's list array
        */
        total: PropTypes.number,
        /**
        * total number of pages to display the data
        */
       pageCount: PropTypes.number
    }),
    /**
    * number of items to be rendered by page
    */
    perPage: PropTypes.number,
    /**
    * function for fetching the data from the api
    */
    fetchList: PropTypes.func,
    /**
    * function for settting shouldReload value
    * @param type bool
    */
    reloadList: PropTypes.func,
    /**
    * function for settting a filter
    */
    setFilter: PropTypes.func,
    /**
    * function for clearing all active filters
    */
    clearAllFilters: PropTypes.func,
    /**
    * function for setting the active sort field name
    */
    setSort: PropTypes.func,
    /**
    * activeFilters labels and display information
    */
    activeFiltersDisplayInfo: PropTypes.shape({
        labels: PropTypes.shape({
            /**
            * label to display when there are no active filters
            */
            noFilters: PropTypes.string,
            /**
            * label to display when filtering
            */
            filteringBy: PropTypes.string,
            /**
            * label for plural and singular of items that are being rendered
            */
            total: PropTypes.shape({
                singlular: PropTypes.string,
                plural: PropTypes.string
            })
        })
    }),
    /**
    * searchbox placeholder
    */
    searchPlaceholder: PropTypes.string,
    /**
    * contains required info for rendering the table's filters
    */
    filtersComponentList: PropTypes.arrayOf(PropTypes.shape({
        //TODO: wip
        /**
        * filter's field name
        */
        fieldName: PropTypes.string,
        /**
        * filter's wrapper className
        */
        className: PropTypes.string,
        /**
        * filter's placeholder
        */
        placeholder: PropTypes.string,
        /**
        * filter's label
        */
        label: PropTypes.string,
        /**
        * filter's current value
        */
        values: PropTypes.any,
        /**
        * type of filter (ex: single, search, multiple...)
        */
        type: PropTypes.string,
        /**
        * option array to display when filter type is select
        */
        options: PropTypes.object
    })),
    /**
    * function for rendering button at the table's top right corner
    */
    renderButton: PropTypes.func,
    /**
    * Message to be displayed when the table has no items
    */
   emptyTableMessage: PropTypes.any
};
export default withRouter(TableList);
