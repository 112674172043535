// @flow
import {
	CHANGE_LAYOUT,
	CHANGE_SIDEBAR_TYPE,
	CHANGE_TOPBAR_THEME,
	SHOW_RIGHT_SIDEBAR,
	CHANGE_PRELOADER,
	TOGGLE_LEFTMENU,
	SHOW_SIDEBAR,
	TOGGLE_REGISTER_MODAL,
	CHANGE_REGISTER_MODAL,
	TOGGLE_ASSSESSMENT_COMMMENTS_MODAL,
	SHOW_TAKE_ASSESSMENT,
	SHOW_COMMENT_BUTTON,
	SHOW_NEXT_BUTTON,
	TOGGLE_UPDATE_COMPANY_MODAL,
	ON_SHOW_ISSUE_EXPANDED_VIEW,
	SET_CONFIRMATION_MODAL,
	LOGOUT_SUCCESS,
} from "../actions/types";

const INIT_STATE = {
	layoutType: "horizontal",
	layoutWidth: "fluid",
	leftSideBarTheme: "dark",
	leftSideBarType: "default",
	topbarTheme: "light",
	preloader: {
		show: false,
		text: "",
		autoHide: true
	},
	showRightSidebar: false,
	isMobile: false,
	showSidebar: true,
	leftMenu: false,
	showRegister: false,
	registerText: {
		title: 'Take a moment to enjoy a good coffee and reflect…',
		button: {
			path: "/welcome",
			text: "SAVE PROGRESS"
		},
		type: 'progress'
	},
	showAssessmentComment: false,
	showTakeAssessment: null,
	showCommentButton: false,
	showNextButton: false,
	showUpgradeCompany: false,
	isShowingIssueExpandedView: false,
	confirmationModal : {
		show: false,
		message: null
	}
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHANGE_LAYOUT:
			return {
				...state,
				layoutType: action.payload
			};
		case CHANGE_PRELOADER:
			return {
				...state,
				preloader: {
					show: action.payload.show,
					text: action.payload.text,
					autoHide: action.payload.autoHide
				}
			};
		case CHANGE_SIDEBAR_TYPE:
			return {
				...state,
				leftSideBarType: action.payload.sidebarType
			};
		case CHANGE_TOPBAR_THEME:
			return {
				...state,
				topbarTheme: action.payload
			};
		case SHOW_RIGHT_SIDEBAR:
			return {
				...state,
				showRightSidebar: action.payload
			};
		case SHOW_SIDEBAR:
			return {
				...state,
				showSidebar: action.payload
			};
		case TOGGLE_LEFTMENU:
			return {
				...state,
				leftMenu: action.payload
			};

		case TOGGLE_REGISTER_MODAL:
			return {
				...state,
				showRegister: action.payload
			};
		case CHANGE_REGISTER_MODAL:
			return {
				...state,
				registerText: {
					title: action.payload.title,
					buttonText: action.payload.buttonText,
					redirectPath: action.payload.redirectPath,
					type: action.payload.type
				}
			};
		case TOGGLE_ASSSESSMENT_COMMMENTS_MODAL:
			return {
				...state,
				showAssessmentComment: action.payload
			};
		case SHOW_TAKE_ASSESSMENT:
			return {
				...state,
				showTakeAssessment: action.payload
			};
		case SHOW_COMMENT_BUTTON:
			return {
				...state,
				showCommentButton: action.payload
			};
		case SHOW_NEXT_BUTTON:
			return {
				...state,
				showNextButton: action.payload
			};

		case TOGGLE_UPDATE_COMPANY_MODAL:
			return {
				...state,
				showUpgradeCompany: action.payload || !state.showUpgradeCompany
			};

		case ON_SHOW_ISSUE_EXPANDED_VIEW:
			return {
				...state,
				isShowingIssueExpandedView: action.payload,
			};
		case SET_CONFIRMATION_MODAL: {
			return {
				...state,
				confirmationModal: action?.payload || {}
			}
		}
		case LOGOUT_SUCCESS: {
			return {
				...state,
				confirmationModal: {
					show: false,
					message: null
				}
			}
		}
		default:
			return state;
	}
};


