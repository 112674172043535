import React from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap';
import { priorityColorsByLabel } from '../../constants/issues';

const PriorityArrow = (props) => {
    const {
        priority,
        className,
        labelExtraClassName = '',
        showLabel = false,
        showTooltip = false,
        targetId = 'priority-arrow'
    } = props;

    const getArrowClassName = () => {
        const colorClassName = `text-${priorityColorsByLabel[priority?.label?.toLowerCase()]}`
        const marginClassName =  showLabel ? 'mr-2' : '';
        return `priority-arrow-icon bx bx-up-arrow-alt ${marginClassName} ${colorClassName}`
    }

    const renderButton = () => {
        return (
            <Button
                id={targetId}
                color="link"
                className={`priority-arrow p-0 text-decoration-none align-items-center ${className || ''}`}
            >
                <i className={getArrowClassName()}></i>
                <span className={`text-white ${labelExtraClassName}`}>
                    {showLabel && priority?.label}
                </span>
            </Button>
        )
    }

    const renderTooltip = () => {
        if(!priority?.label || !showTooltip) return null
        return (
            <UncontrolledTooltip placement="bottom" target={targetId}>
                {priority?.label}
            </UncontrolledTooltip >
        )
    }


    return (
        <>
            {renderButton()}
            {renderTooltip()}
        </>
    )
}

export default PriorityArrow
