import _ from 'lodash';
import {
    ANSWERS_SUCCESS,
    SELECT_ANSWER,
    DESELECT_ANSWER,
    SET_COMMENT,
    SAVE_ANSWERS_SUCCESS,
    ANSWERS_FAILURE,
    CLEAR_ANSWERS,
    RESET_ASSESSMENT_ANSWERS,
    RESET_ASSESSMENT_ANSWERS_SUCCESS,
    SET_ASSESSMENT_COMMENT,
    CREATE_EXECUTION_SUCCESS,
    ON_CLEAN_ANSWERS,
    ASSESSMENT_EXECUTION_FAILURE,
    ASSESSMENT_EXECUTION_SUCCESS,
    COMPLIANCE_ASSESSMENT_FAILURE,
    COMPLIANCE_ASSESSMENT_SUCCESS,
    SET_COMPLIANCES,
    ASSESSMENT_REQUEST,
    ANSWERS_REQUEST,
} from './types';
import { changePreloader, toggleAssessmentCommentModal } from './layout';
import { setAlert } from './alerts';
import { setNextQuestion, assessmentSuccess } from './assessment';
import { trackEvent } from '../utils/gaTracker';
import { getAssessmentAnswersFromLocalStorage } from '../utils/helpers';
import { errorActionCreator } from './action-creators/errors';
import { successActionCreator } from './action-creators/success';
import errorMessages from '../constants/errorMessages';
import { defaultIndexPath } from '../constants/navigation';
import { invalidateQueriesByKeys } from '../utils/queryHelpers';
import setAuthToken from '../utils/axios/setAuthToken';

export const onCleanAnswers = () => {
    return {
        type: ON_CLEAN_ANSWERS,
        payload: null,
    }
}

export const fetchAnswers = (assessmentId, fromAPI = true) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { AssessmentAPI } = API;
            dispatch({ type: ANSWERS_REQUEST })
            const response = fromAPI ? await AssessmentAPI.handlers.fetchAnswers(assessmentId) : getAssessmentAnswersFromLocalStorage();
            if (!localStorage.getItem("questions")) { dispatch(onCleanAnswers()); }
            dispatch(fetchAnswersSuccess(response?.data));
        } catch (error) {
            dispatch(answersError(error));
            console.log(error);
        }
    };
};

export const fetchAssessmentExecution = (fromAPI = true, companyId = null, type = null) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { AssessmentAPI } = API;
            dispatch({ type: ASSESSMENT_REQUEST });
            let response = {};
            if (fromAPI) {
                const assessmentResponse = await AssessmentAPI.handlers.fetchAssessment(type);
                dispatch(assessmentSuccess(assessmentResponse?.data));
                response = await AssessmentAPI.handlers.fetchAnswers(assessmentResponse?.data?.id, companyId);
            } else {
                if (!localStorage.getItem("questions")) { dispatch(onCleanAnswers()); }
                response = getAssessmentAnswersFromLocalStorage();
            }
            dispatch(fetchAnswersSuccess(response?.data));
            dispatch(successActionCreator(ASSESSMENT_EXECUTION_SUCCESS));
        } catch (error) {
            dispatch(answersError(error));
            const errorMesage = error?.response?.data?.message || "Something went wrong..."
            dispatch(errorActionCreator(ASSESSMENT_EXECUTION_FAILURE, { message: errorMesage }));
        }
    };
};

export const fetchComplianceAssessment = (complianceId = null, companyId = null) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { AssessmentAPI } = API;
            dispatch({ type: ASSESSMENT_REQUEST });
            let assessmentResponse = null;
            if (_.isNil(complianceId)) {
                const response = await AssessmentAPI.handlers.fetchCompliances();
                dispatch(successActionCreator(SET_COMPLIANCES, response?.data));
                //If no compliance id is given it will fetch the compliance list and set CIS as default
                //TODO: when order is available instead of setting CIS as default, set the compliance with order = 1 as default
                assessmentResponse = await AssessmentAPI.handlers.fetchAssessment(_.find(response?.data, ['name', 'CIS'])?.id);
            } else {
                assessmentResponse = await AssessmentAPI.handlers.fetchAssessment(complianceId);
            }
            dispatch(assessmentSuccess(assessmentResponse?.data));
            const answersResponse = await AssessmentAPI.handlers.fetchAnswers(assessmentResponse?.data?.id, companyId);
            dispatch(successActionCreator(COMPLIANCE_ASSESSMENT_SUCCESS, answersResponse?.data));
        } catch (error) {
            const message = error?.response?.data?.errorMessage || errorMessages.defaultGet
            dispatch(errorActionCreator(COMPLIANCE_ASSESSMENT_FAILURE, { message }));
            console.log(error);
        }
    };
}

export const fetchAnswersSuccess = (answers) => {
    return {
        type: ANSWERS_SUCCESS,
        payload: answers
    }
}

export const setComment = (comment) => {
    return {
        type: SET_COMMENT,
        payload: comment
    }
}

export const saveAssessmentComment = (comment, assessmentExecutionStatus, history) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { AssessmentAPI } = API;
            await AssessmentAPI.handlers.postAssessmentComment(comment);
            invalidateQueriesByKeys(AssessmentAPI.invalidators.postAssessmentComment, queryClient);
            trackEvent("save_comment", "assessment", "save_comment");
            dispatch(setAssessmentComment(comment?.comment));
            if (assessmentExecutionStatus !== 1) {
                dispatch(saveAssessmentExecutionStatus(comment.assessmentExecutionId, history));
            } else {
                dispatch(toggleAssessmentCommentModal(false));
                dispatch(changePreloader(true, "Analyzing your answers"));
                setTimeout(() => {
                    history.push(defaultIndexPath);
                }, 2500);
            }
        } catch (error) {
            const message = error?.response?.data?.message || 'Oops! We didn’t catch that. Try again';
            dispatch(setAlert(message, 'danger'));
        }
    };
}

export const setAssessmentComment = (comment) => {
    return {
        type: SET_ASSESSMENT_COMMENT,
        payload: comment
    }
}

//  saves all answers
//  TODO: REDUX-CLEANUP -> Remove this middleware if possible
export const saveAnswers = (answers, history, fromCybercheckup = false) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { AssessmentAPI } = API;
            const body = {
                ...answers,
                from_cybercheckup: fromCybercheckup
            };
            const response = await AssessmentAPI.handlers.postAnswers(body);
            invalidateQueriesByKeys(AssessmentAPI.invalidators.postAnswers, queryClient);
            dispatch(saveAnswersSuccess(response?.data));
            if (fromCybercheckup) {
                localStorage.removeItem('token');
                setAuthToken(null);
            }

            //  Do not redirect if save answers is triggered from cybercheckup
            if (!fromCybercheckup) {
                if (localStorage.getItem("assessmentExecutionStatus") === '1') {
                    history.push(defaultIndexPath);
                    dispatch(changePreloader(true, "Analyzing your answers"));
                } else {
                    dispatch(changePreloader(false));
                }
            }
        } catch (error) {
            console.log('Save Answers Error: ', error);
            if (fromCybercheckup) {
                localStorage.removeItem('token');
                setAuthToken(null);
            }
            dispatch(changePreloader(false));
            const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : "Oops! We didn’t catch that. Try again";
            dispatch(setAlert(errorMessage, 'danger'))
        }
    };
}

export const saveAnswersSuccess = (answers) => {
    return {
        type: SAVE_ANSWERS_SUCCESS,
        payload: answers
    }
}

//saves a single answer
//  TODO: REDUX-CLEANUP -> Remove this middleware if possible
export const saveAnswer = (answer, isAssessmentEnd) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { AssessmentAPI } = API;
            await AssessmentAPI.handlers.postAnswer(answer);
            invalidateQueriesByKeys(AssessmentAPI.invalidators.postAnswer, queryClient);
            //  Selecting or deselecting answer
            const { optionId } = answer;
            dispatch(optionId ? selectAnswer(answer) : deselectAnswer(answer));

            if (isAssessmentEnd) {
                dispatch(toggleAssessmentCommentModal(true));
            } else {
                setTimeout(() => { dispatch(setNextQuestion()) }, 1000);
            }
        } catch (error) {
            const message = error?.response?.data?.message || 'Oops! We didn’t catch that. Try again';
            dispatch(setAlert(message, 'danger'));
        }
    };
}

export const selectAnswer = (answer) => {
    return {
        type: SELECT_ANSWER,
        payload: answer
    }
}

export const deselectAnswer = (question) => {
    return {
        type: DESELECT_ANSWER,
        payload: question
    }
}

export const answersError = (message) => {
    return {
        type: ANSWERS_FAILURE,
        payload: message
    }
}

export const clearAnswers = () => {
    return {
        type: CLEAR_ANSWERS
    }
}

export const resetAssessment = () => {
    return {
        type: RESET_ASSESSMENT_ANSWERS
    }
}

export const resetAssessmentSuccess = (message) => {
    return {
        type: RESET_ASSESSMENT_ANSWERS_SUCCESS,
        payload: message
    }
}

export const saveAssessmentExecutionStatus = (assessmentExecutionId, history) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { AssessmentAPI } = API;
            await AssessmentAPI.handlers.postAssessmentExecutionStatus(assessmentExecutionId);
            invalidateQueriesByKeys(AssessmentAPI.invalidators.postAssessmentExecutionStatus, queryClient);
            dispatch(toggleAssessmentCommentModal(false));
            dispatch(changePreloader(true, "Analyzing your answers"));
            setTimeout(() => {
                history.push(defaultIndexPath);
            }, 2500);
        } catch (error) {
            dispatch(setAlert("Oops! We didn’t catch that. Try again", 'danger'));
            console.log(error);
        }
    };
};

//  TODO: REDUX-CLEANUP -> Remove this middleware if possible
export const createExecutionAnswers = (assessment_id, history) => {
    return async (dispatch, getState, { API, queryClient }) => {
        try {
            const { AssessmentAPI } = API;
            const answers = {
                assessment_id,
                questionAnswers: {},
                questionsByCategory: {},
                sectionComments: {},
                comment: '',
                completed: false
            };
            const response = await AssessmentAPI.handlers.postAnswers(answers);
            invalidateQueriesByKeys(AssessmentAPI.invalidators.postAnswers, queryClient);
            dispatch(createExecutionAnswersSuccess(response?.data));
            history.push('/dashboard/assessment');
        } catch (error) {
            const message = error?.response?.data?.message || 'Oops! We didn’t catch that. Try again';
            dispatch(setAlert(message, 'danger'));
        }
    };
}

export const createExecutionAnswersSuccess = (answers) => {
    return {
        type: CREATE_EXECUTION_SUCCESS,
        payload: answers
    }
}
