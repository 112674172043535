import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { Button } from 'reactstrap';
import { companyPageSize } from '../../constants/company';
import API from '../../api';
import { getParentPath, getSafeArray, parseCompaniesToOptions } from '../../utils/helpers';
import { onImpersonationLoading, onImpersonationSuccess } from '../../actions/impersonation';
import { setAlert } from '../../actions/alerts';
import { impersonationPathsWhiteList } from '../../constants/company';
import SelectDropdownIndicator from './fields/SelectDropdownIndicator';
import useScrollingQuery from '../../hooks/useScrollingQuery';
import useCustomMutation from '../../hooks/useCustomMutation';
import { successActionCreator } from '../../actions/action-creators/success';
import { SHOULD_RELOAD_IMPERSONATION } from '../../actions/types';

//  Initial filters
const initialFilters = {
  limit: companyPageSize,
  searchText: null,
  sort: ['name', 'ASC'],
};

//  Component
const ImpersonationCompanyInfiniteDropdown = (props) => {
  //  Initializing APIs
  const { CompanyAPI, UserAPI } = API;

  //  Memoized dispatch
  const defaultDispatch = useDispatch();
  const dispatch = useCallback(defaultDispatch, []);

  //  Watching redux store
  const impersonatedCompanyId = useSelector((state) => state.impersonation.companyId);
  const impersonatedCompanyName = useSelector((state) => state.impersonation.name);
  const shouldReload = useSelector((state) => state.impersonation.shouldReload);
  const defaultImpersonation = useSelector(state => state.navigation?.defaultImpersonation);

  // Component state
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [filters, setFilters] = useState(initialFilters);
  const [requestMore, setRequestMore] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedCompanyUpdated, setSelectedCompanyUpdated] = useState(false);
  const [shouldApplyDefault, setShouldApplyDefault] = useState(false);

  //  Getting company list
  const {
    elements: companies,
    error: impersonationListError,
    loading,
    hasNextPage,
    fetchNextPage,
    clearQueryData,
  } = useScrollingQuery({
    baseKey: CompanyAPI.keys.getImpersonationList,
    key: [CompanyAPI.keys.getImpersonationList, filters],
    queryFunction: CompanyAPI.handlers.getImpersonationList,
    parseElements: parseCompaniesToOptions,
    isDisabled,
    valueToExtract: 'rows',
    initialPageParam: 0,
  });

  // Handle clear clicked
  const clearState = (fromDisableState = false) => {
    setSelectedCompany(null);
    setSearchText('');
    clearQueryData();
    if (!fromDisableState) { return; }
    setFilters(initialFilters);
  };

  //  Getting location
  const location = useLocation();

  //  Watching selected company id and company name from redux
  useEffect(() => {
    setSelectedCompanyUpdated(true);
    const { value: currentSelectedId } = selectedCompany || { value: null };
    if (currentSelectedId === impersonatedCompanyId) { return; }
    if (_.isNil(impersonatedCompanyId) && _.isNil(impersonatedCompanyName)) {
      clearState(false);
      return;
    }
    setSelectedCompany({ value: impersonatedCompanyId, label: impersonatedCompanyName });
  }, [impersonatedCompanyId]);

  //  Watching location changes
  useEffect(() => {
    if (!location || !location.pathname) { return; }
    const { pathname } = location;

    // Getting parent path
    const parentPath = getParentPath(pathname);

    //  Looking for white listed path by parent path
    const matchingPath = impersonationPathsWhiteList.find((item) => item.pathname === parentPath);
    //  If does not exist in white list, disable dropdown
    if (!matchingPath) { setIsDisabled(true); return; }
    const { validateLocation } = matchingPath;
    //  Validate location forces to look for an id passed on the state (edge case for members table action)
    if (!validateLocation) { setIsDisabled(false); return; }
    setIsDisabled(true);
  }, [location]);

  //  Watching location path changes
  useEffect(() => {
    setShouldApplyDefault(true);
  }, [location?.pathname])


  //  Watching company list error
  useEffect(() => {
    if (!impersonationListError) { return; }
    dispatch(setAlert('Error getting companies!', 'danger'));
  }, [impersonationListError]);

  // Watching reload flag
  useEffect(() => {
    if(shouldReload) {
      impersonateCompany(impersonatedCompanyId)
      dispatch(successActionCreator(SHOULD_RELOAD_IMPERSONATION, false))
    }
  },[shouldReload])

   // Watching companies to set first as default
   useEffect(() => {
    if(!shouldApplyDefault || isDisabled || !defaultImpersonation) return;
    if(!selectedCompany) {
      let companyOption = null;
      if (process.env.REACT_APP_DEFAULT_COMPANY) {
        companyOption = JSON.parse(process.env.REACT_APP_DEFAULT_COMPANY || "{}")
      } else {
        const safeCompanies = getSafeArray(companies);
        if(safeCompanies?.length < 1) return;
        companyOption = safeCompanies[0];
      }
      if (companyOption && Object.keys(companyOption)?.length !== 0) handleDropdownChange(companyOption);
    }
    setShouldApplyDefault(false);
  }, [companies, selectedCompany, shouldApplyDefault, defaultImpersonation])

  //  Post to select company
  const {
    data: impersonateResponse,
    error: impersonateError,
    isLoading: impersonateLoading,
    mutate: impersonateCompany,
  } = useCustomMutation(
    (params) => UserAPI.handlers.impersonateCompany(params),
    UserAPI.invalidators.impersonateCompany
  );

  //  Watching impersonate response
  useEffect(() => {
    if (!impersonateResponse) { return; }
    dispatch(onImpersonationSuccess(impersonateResponse));
  }, [impersonateResponse]);

  //  Watching impersonate error
  useEffect(() => {
    if (!impersonateError) { return; }
    dispatch(setAlert('Error selecting company!', 'danger'));
    setSelectedCompany(null);
    dispatch(onImpersonationSuccess(null));
  }, [impersonateError]);

  //  Watching selected company changes
  useEffect(() => {
    if (!selectedCompanyUpdated) return;
    if (!selectedCompany) {
      dispatch(onImpersonationLoading(true));
      impersonateCompany(null);
      return;
    }

    const { value } = selectedCompany;
    if (value !== impersonatedCompanyId || impersonateLoading) {
      dispatch(onImpersonationLoading(true));
      impersonateCompany(value);
    }
  }, [selectedCompany, selectedCompanyUpdated]);

  //  Watching flag to request more companies
  useEffect(() => {
    if (!requestMore) { return; }
    setRequestMore(false);
    if (!hasNextPage || loading) { return; }
    fetchNextPage();
  }, [requestMore]);

  //  Function to handle change on select
  const handleDropdownChange = (company) => {
    setSelectedCompany(company);
  };

  //  Funciton to handle chanes on input (search text case)
  const handleInputChange = (searchTerm) => {
    setSearchText(searchTerm);
    if (loading || (searchTerm.length > 0 && searchTerm.length < 3)) { return; }
    setFilters({ ...filters, searchText: searchTerm });
  };

  //  Function to handle on scroll bottom
  const onScrollBottom = () => {
    setRequestMore(true);
  };

  //  Function to render clear button
  const renderClearButton = () => {
    if (!selectedCompany) { return null; }
    return (
      <Button
        className="close dropdown-clear"
        onClick={() => { clearState(false); }}
      >
        <i className='bx bx-x' />
      </Button>
    );
  };

  // Select Dropdown Indicator
  const DropdownIndicator = (props) => {
    return <SelectDropdownIndicator {...props} />
  }

  //  Rendering
  return (
    <div className="company-dropdown-container">
      <Select
        inputValue={searchText}
        value={selectedCompany}
        options={companies}
        onChange={handleDropdownChange}
        onInputChange={handleInputChange}
        onMenuScrollToBottom={onScrollBottom}
        className="react-select-container company-dropdown"
        classNamePrefix="react-select"
        placeholder={'Pick a Company'}
        components={{ DropdownIndicator }}
        isDisabled={isDisabled}
        isLoading={loading}
        isSearchable={true}
        menuPosition={'fixed'}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      />
      {renderClearButton()}
    </div>
  );
};

export default ImpersonationCompanyInfiniteDropdown;
