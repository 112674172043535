import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button } from "reactstrap";
import { useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners';
import BasicModal from '../layout/BasicModal';
import Avatar from '../layout/avatars/Avatar'
import ErrorMessage from '../../components/layout/ErrorMessage'
import CustomSpinner from '../../components/layout/CustomSpinner';
import makeLoadingState from '../../selectors/loadingSelector';
import makeErrorMessage from '../../selectors/errorsSelector';
import {
    CREATE_ISSUE_SUCCESS,
    CREATE_ISSUE_FAILURE,
    ADD_COMMENT_SUCCESS,
    RESET_MODAL_OPTIONS_USERS
} from '../../actions/types';
import { setAlert } from "../../actions/alerts"
import { validateEmptyTextField } from '../../utils/validations';
import { renderCustomSelect, renderTextArea } from '../../utils/renderFormFields';
import CRITICALITY from '../../constants/criticality';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { getIssueModalOptions, onReloadIssues } from '../../actions/issues';
import CallbackSelectField from '../layout/fields/SelectFieldCallback';
import { Can } from '../../contexts/AbilityContext';
import Tabs from '../layout/tabs/Tabs';
import CommentsSection from '../layout/comments/CommentsSection';
import { errorActionCreator } from '../../actions/action-creators/errors';
import { successActionCreator } from '../../actions/action-creators/success';
import companyIdSelector from '../../selectors/companyIdSelector';
import companyInfoSelector from '../../selectors/companyInfoSelector';
import API from '../../api';
import { getCreateIssueSuccessMessage } from '../../utils/alertHelpers';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomMutation from '../../hooks/useCustomMutation';
import { issueTypes } from '../../constants/issues';
import { PRIVILEGE_SOURCES, READ_ACTION } from '../../constants/privileges';
import useCanAbility from '../../hooks/useCanAbility';
import useUserType from '../../hooks/useUserType';
import { ISSUE_COMMENT_SECTION_TYPE } from '../../constants/common';
import { getSafeArray } from '../../utils/helpers';
import issuesInfoSelector from '../../selectors/issuesInfoSelector';

const IssueCreate = (props) => {
    //  Parsing params
    const {
        showModal,
        toggleModal,
        type = "issue",
        title,
        criticality,
        solution,
        parentId,
        parentCompanyId,
        issueType = issueTypes.VULNERABILITIES,
    } = props;

    //  Initializing APIs
    const { IssueAPI } = API;

    //  Component state
    const [formErrors, setFormErrors] = useState({});
    const [selectedCriticality, setSelectedCriticality] = useState(CRITICALITY[criticality?.value]?.className);
    const [criticalityClassName, setCriticalityClassname] = useState(null);
    const [solutionOptions, setSolutionOptions] = useState([]);
    const [assigneeOptions, setAssigneeOptions] = useState([]);
    const [codeOptions, setCodeOptions] = useState([{ label: 'Internal', value: 'internal' }]);
    const [criticalityOptions, setCriticalityOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [issueName, setIssueName] = useState(null);
    const [issueDescription, setIssueDescription] = useState(null);
    const [issueCode, setIssueCode] = useState(null);
    const [comments, setComments] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);

    //  Selectors
    const getLoadingState = makeLoadingState(['ISSUE_MODAL_OPTIONS']);
    const getErrorMessage = makeErrorMessage(['ISSUE_MODAL_OPTIONS']);

    //  Watching redux store
    const isLoading = useSelector((state) => getLoadingState(state));
    const errorMessage = useSelector((state) => getErrorMessage(state));
    const options = useSelector((state) => issuesInfoSelector(state, 'modalOptions', issueType));
    const role = useSelector((state) => state.auth.user.roles[0]?.name);
    const user = useSelector((state) => state.auth.user);

    // Getting needed info from redux store
    const isMemberView = useSelector(state => state.impersonation.isMemberView);
    const selectedCompanyId = useSelector(state => companyIdSelector(state));
    const selectedCompanyName = useSelector(state => companyInfoSelector(state, "name"));

    //  Component hooks
    const { dispatch } = useMemoizedDispatch();
    const ability = useCanAbility();
    const userType = useUserType();
    const {
        data: createIssueResponse,
        error: createIssueError,
        mutate: callCreateIssue,
        isLoading: createIssueLoading
    } = useCustomMutation(
        (params) => IssueAPI.handlers.createIssue(params),
        IssueAPI.invalidators.createIssue,
    );

    // Component variables
    const canViewCompanyDropdown = ability.can(READ_ACTION, PRIVILEGE_SOURCES.ISSUES.COMPANY_DROPDOWN);

    //  Watching create issue response
    useEffect(() => {
        if (!createIssueResponse) { return; }
        const payload = { data: createIssueResponse, isMemberView, issueType: issueTypes.GRC };
        dispatch(successActionCreator(CREATE_ISSUE_SUCCESS, payload));
        const successMessage = getCreateIssueSuccessMessage(createIssueResponse);
        dispatch(onReloadIssues(true, issueType));
        dispatch(setAlert(successMessage, 'success', true, 5000));
        closeModal();
    }, [createIssueResponse]);

    //  Watching create issue error
    useEffect(() => {
        if (!createIssueError) { return; }
        //  TODO: call set form errors from here
        const message = 'Error creating issue. ';
        dispatch(errorActionCreator(CREATE_ISSUE_FAILURE, { message }));
        dispatch(setAlert(message, 'danger'));
    }, [createIssueError]);

    useEffect(() => {
        if (showModal) {
            setFormErrors({})
        }
    }, [showModal, setFormErrors]);

    // Watching select company name and id changes
    useEffect(() => {
        if (!_.isNil(selectedCompanyId)) {
            setIssueCode({ value: selectedCompanyId, label: selectedCompanyName });
        } else {
            setIssueCode(null)
        }
    }, [selectedCompanyId, selectedCompanyName])

    useEffect(() => {
        const criticalityClass = CRITICALITY[selectedCriticality?.value || criticality?.value]?.className;
        setCriticalityClassname(criticalityClass);
    }, [selectedCriticality, setCriticalityClassname, criticality]);

    useEffect(() => {
        if (validateEmptyTextField(issueName)) {
            setFormErrors({ ...formErrors, name: validateEmptyTextField(issueName) })
        } else {
            setFormErrors(_.omit(formErrors, ['name']));
        }
    }, [issueName, setFormErrors, validateEmptyTextField]);

    const renderUserOption = (user, profileImage = null) => {
        const { full_name } = user;
        return (
            <div className="d-inline-flex align-items-center">
                <Avatar user={user} profileImage={profileImage} customClassName="user-option" />
                {full_name}
            </div>
        );
    }

    useEffect(() => {
        let userOptions = [];
        if (options.users?.cydekicks) {
            userOptions = options.users?.cydekicks?.map((cydekick) => {
                return { label: renderUserOption(cydekick), value: cydekick.id }
            });
        }
        if (options.users?.company_members) {
            userOptions = userOptions.concat(options.users?.company_members?.map((member) => {
                return { label: renderUserOption(member), value: member.id };
            }));
        }

        setSolutionOptions([
            {
                options: options.solutions.map((solution) => {
                    return { label: solution.abbreviation, value: solution.id }
                })
            }
        ]);
        if (options.users.cydekicks) {
            setAssigneeOptions([
                {
                    options: userOptions
                }
            ]);
        }
        if (options.companies.length > 0) {
            const orderedCompanies = options.companies.map((company) => {
                return { label: company.name, value: company.id }
            });
            setCodeOptions([
                {
                    options: [
                        // TODO: check with new logic for internal issues
                        // { label: 'Internal', value: 'internal' },
                        ...orderedCompanies
                    ]
                }
            ]);
        }
        setStatusOptions([
            {
                options: _.filter(options.statuses, ['hidden', false]).map(({ label, id }) => {
                    return { label, value: id }
                })
            }
        ]);

        setCriticalityOptions([
            {
                options: _.filter(options.criticalities, ['hidden', false]).map(({ label, id }) => {
                    return { label, value: id }
                })
            }
        ]);
    }, [options, setSolutionOptions, setAssigneeOptions, setCodeOptions, setCriticalityOptions, setStatusOptions]);

    const handleIssueName = (value) => {
        setIssueName(value);
    }

    const handleIssueDescription = (value) => {
        setIssueDescription(value);
    }

    const onTryAgainButtonClick = () => {
        dispatch(getIssueModalOptions(null, issueType, userType));
    }

    const closeModal = () => {
        toggleModal();
        setSelectedCriticality(null);
        setIssueName(null);
        setIssueDescription(null);
        setIssueCode(!_.isNil(selectedCompanyId) ? { value: selectedCompanyId, label: selectedCompanyName } : null);
        setComments([]);
        if (canViewCompanyDropdown) { dispatch(successActionCreator(RESET_MODAL_OPTIONS_USERS)); }
        if (type !== 'subtask') setCriticalityClassname(null);
    }

    const tryAgainButton = (
        <button
            className="btn btn-light"
            onClick={onTryAgainButtonClick}
        >
            Try again
        </button>
    );

    const setCreateIssuePayload = (values) => {
        const {
            name,
            description,
            attachments,
            criticality,
            status,
            assignee,
            solution,
            code,
            comments
        } = values;

        const formValues = {
            name,
            description: description || '',
            files: { links: attachments },
            severity: criticality?.value,
            status: status?.value || 0,
            assigned_to: assignee?.value,
            solution_id: solution?.value,
            company_id: type === 'subtask' ? parentCompanyId : (code?.value !== 'internal' ? code?.value : null),
            parent_issue_id: type === 'subtask' ? parentId : null,
            comments: { list: comments }
        }

        return formValues;
    }

    const renderIssueTypeDropdown = () => {
        if (isMemberView || type === 'subtask') return;
        return (
            <Can I={READ_ACTION} a={PRIVILEGE_SOURCES.ISSUES.COMPANY_DROPDOWN}>
                <div className="d-flex justify-content-center w-100">
                    <div className="mr-2 mb-2" style={{ width: "160px", marginTop: "1px" }}>
                        <CallbackSelectField
                            name="company"
                            placeholder="Member"
                            value={issueCode}
                            options={codeOptions}
                            callback={(value) => {
                                setIssueCode(value)
                                if (type !== 'subtask') dispatch(getIssueModalOptions(value?.value, issueType, userType));
                            }}
                        />
                    </div>
                </div>
            </Can>
        )
    }

    //  Function to create issue
    const onSubmit = (values) => {
        const payloadToCreate = setCreateIssuePayload(values);
        callCreateIssue(payloadToCreate);
    };

    const onAddCommentCallback = (value) => {
      if (value) {
        const status_id = selectedStatus || 0;
        const status_name = getSafeArray(options?.statuses).find((status) => status_id === status.id)?.label
        setComments([
          {
            updated_at: new Date(),
            user: {
              id: user.id,
              full_name: user.name,
              profile_image: user.profileImage,
            },
            status: {
                id: status_id,
                name: status_name,
            },
            content: value,
          },
          ...comments,
        ]);
        dispatch(successActionCreator(ADD_COMMENT_SUCCESS));
      }
    };

    return (

        <BasicModal
            header={title || "Create new issue"}
            headerClassName={`modal-header-status bg-${criticalityClassName || 'light-gray'}`}
            showModal={showModal}
            toggleModal={() => closeModal()}
            customClassName={`issue-manager-modal issue-create ${type}`}
        >
            {errorMessage && (
                <ErrorMessage
                    text={errorMessage}
                    button={tryAgainButton}
                    customStyle={{ paddingTop: "5%" }}
                />
            )}

            {(isLoading || createIssueLoading) && !errorMessage && (
                <CustomSpinner
                    customStyle={{ minHeight: "200px" }}
                />
            )}

            {!isLoading && !createIssueLoading && !errorMessage && (
                <Form
                    onSubmit={onSubmit}
                    shouldValidate={true}
                    render={({ handleSubmit, submitting, invalid, values, form }) => (
                        <form
                            className="issue-modal-body"
                            onSubmit={event => {
                                if (invalid) {
                                    dispatch(setAlert('One or more fields have an error. Please check and try again', 'danger'));
                                }

                                handleSubmit(event);
                            }}
                        >
                            {/* TODO: add the other role */}
                            {renderIssueTypeDropdown()}
                            <div className="d-block d-lg-inline-flex w-100" >
                                <div className="left-column">
                                    <Field
                                        render={renderTextArea}
                                        name="name"
                                        label="Issue name"
                                        placeholder="Name this issue…"
                                        customClassName="dark"
                                        customError={formErrors.name}
                                        maxRows={1}
                                        callback={handleIssueName}
                                        beforeSubmit={() => {
                                            form.change("name", issueName);
                                            form.change("code", issueCode);
                                            form.change("comments", comments)
                                            if (validateEmptyTextField(issueName)) {
                                                setFormErrors({ ...formErrors, name: validateEmptyTextField(issueName) });
                                                dispatch(setAlert('One or more fields have an error. Please check and try again', 'danger'));
                                                return false;
                                            } else if (type !== 'subtask' && !issueCode && canViewCompanyDropdown) {
                                                dispatch(setAlert('Please select the type of issue', 'danger'));
                                                return false;
                                            }
                                        }}
                                    />
                                    <Field
                                        render={renderTextArea}
                                        name="description"
                                        label="Description"
                                        placeholder="Add Description…"
                                        customClassName="dark"
                                        customError={formErrors.description}
                                        callback={handleIssueDescription}
                                        maxRows={5}
                                        beforeSubmit={() => {
                                            form.change("description", issueDescription);
                                        }}
                                    />
                                    <OnChange name="description">
                                        {() => setFormErrors(_.omit(formErrors, ['description']))}
                                    </OnChange>
                                    <br />
                                </div>
                                <div className="right-column">
                                    <Field
                                        render={renderCustomSelect}
                                        name="status"
                                        label="Status"
                                        placeholder="Unassigned"
                                        options={statusOptions}
                                        selectedValue={values.status}
                                        customClassName={values.status ? 'has-value' : ''}
                                        customError={formErrors.status}
                                    />
                                    <OnChange name="status">
                                        {() => {
                                            setFormErrors(_.omit(formErrors, ['status']))
                                            if (values.status?.value === 0) form.change("assignee", null)
                                            setSelectedStatus(values.status?.value || 0)
                                        }}
                                    </OnChange>
                                    <Field
                                        render={renderCustomSelect}
                                        name="criticality"
                                        label="Criticality"
                                        placeholder="Assign criticality"
                                        initialValue={criticality}
                                        options={criticalityOptions}
                                        customClassName={criticalityClassName}
                                        customError={formErrors.criticality}
                                    />
                                    <OnChange name="criticality">
                                        {() => {
                                            setFormErrors(_.omit(formErrors, ['criticality']));
                                            setSelectedCriticality(values.criticality);
                                        }}
                                    </OnChange>
                                    <Field
                                        render={renderCustomSelect}
                                        name="assignee"
                                        label="Assignee"
                                        placeholder="Cydekicks"
                                        options={assigneeOptions}
                                        customClassName={values.assignee ? 'has-value' : ''}
                                        customError={formErrors.assignee}
                                    />
                                    <OnChange name="assignee">
                                        {() => {
                                            setFormErrors(_.omit(formErrors, ['assignee']))
                                            if (values.assignee && (values.status?.value === 0 || !values.status)) form.change("status", { label: options.statuses[1]?.label, value: 1 })
                                        }}
                                    </OnChange>
                                    <Field
                                        render={renderCustomSelect}
                                        name="solution"
                                        label="Solution"
                                        placeholder="Pick solution"
                                        initialValue={solution}
                                        options={solutionOptions}
                                        customClassName={values.solution ? 'has-value' : ''}
                                        customError={formErrors.solution}
                                    />
                                    <OnChange name="solution">
                                        {() => setFormErrors(_.omit(formErrors, ['solution']))}
                                    </OnChange>
                                </div>
                            </div>
                            <div className="w-100">
                                <Tabs tabs={[
                                    {
                                        name: "Change log",
                                        content: (
                                            <CommentsSection
                                                comments={comments}
                                                callback={onAddCommentCallback}
                                                commentType={ISSUE_COMMENT_SECTION_TYPE}
                                            />
                                        )
                                    }
                                ]} />
                            </div>
                            <ModalFooter style={{ marginTop: "46px" }}>
                                <div className="text-center" >
                                    <Button
                                        color="link"
                                        className="text-white font-weight-bold px-2"
                                        onClick={closeModal}
                                        style={{ marginRight: "11px" }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={`btn btn-light text-dark font-weight-bold ${submitting ? 'disabled' : ''}`}
                                        type="submit"
                                        disabled={submitting}
                                    >
                                        {submitting ? 'Processing...' : 'Create issue'}
                                    </Button>
                                </div>
                            </ModalFooter>
                        </form>
                    )}
                />
            )}
        </BasicModal>
    );
};

export default IssueCreate;

