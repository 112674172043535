import _, { parseInt } from 'lodash';
import React, { useState } from 'react'
import { Input } from 'reactstrap';
import { formatWholeNumber } from '../../../utils/helpers';

const TimeField = (props) => {

    const {
        label,
        index,
        value='',
        maxDigitLength=2,
        maxLimit,
        inputName="time-field",
        fieldName,
        className="",
        placeholder="00",
        callback
    }= props;

    const [inputValue, setInputValue] = useState(value);

    const onInputChange = (event) => {
        const { value } = event.target;
        const number = parseInt(formatWholeNumber(value || 0),10);
        const numberString = number?.toString();
        const reachedMaxDigitLength = numberString?.length > maxDigitLength
        const exceedsMaxLimit = !_.isNil(maxLimit) && (number > maxLimit);
        if(reachedMaxDigitLength || exceedsMaxLimit) return;
        const newValue = padLeadingZeros(numberString);
        setInputValue(newValue)
        const params = {
            count: number,
            name: inputName,
            label: numberString,
            hasMaxDigits: numberString?.length === maxDigitLength,
            index,
            isEmpty: !value || value === "0",
            fieldName
        }
        callback(params)
    }

    const padLeadingZeros = (value) => {
        while (value.length < maxDigitLength) {
            value = '0' + value;
        }
        return value;
    }

    return (
        <div className={`time-field-container d-inline-block ${className}`}>
            <div className="time-field-label text-center">{label}</div>
            <Input
                className="input time-field-input"
                name={inputName}
                placeholder={placeholder}
                value={inputValue}
                onChange={onInputChange}
                aria-label={`${inputValue ? inputValue : "0" } ${label}`}
            />
        </div>
    )
}

export default TimeField;
