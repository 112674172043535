import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import API from '../../api';
import { setAlert } from '../../actions/alerts';
import companyIdSelector from '../../selectors/companyIdSelector';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import useCustomQuery from '../../hooks/useCustomQuery';
import DevicesPartnerFilters from './DevicesPartnerFilters';
import { alertTypes } from '../../constants/alerts';

const CompanyDevicesDetails = (props) => {
  //  Initializing API
  const { DeviceAPI } = API;
  const {
    setSelectedPartner,
    deviceByPartnerCount
  } = props;

  //  Watching redux state
  const company_id = useSelector((state) => companyIdSelector(state));

  //  Component hooks
  const { dispatch } = useMemoizedDispatch();

  //  API Calls
  const {
    data: countResponse,
    error: countError,
  } = useCustomQuery(
    [DeviceAPI.keys.getDevicesCount, company_id],
    ({ queryKey }) => DeviceAPI.handlers.getDevicesCount(queryKey[1]),
  );

  //  Watching count error
  useEffect(() => {
    if (!countError) { return; }
    dispatch(setAlert('Error getting devices count. ', alertTypes.error));
  }, [countError, dispatch, setAlert]);

  //  Function to render info item
  const renderInfoItem = (title, content) => {
    return (
      <div className="device-info-item">
        <div className="device-info-title">{title}</div>
        <div className="device-info-content">{content}</div>
      </div>
    );
  };

  // Function to render Devices Partner Filters

  const renderDevicesFilters = () => {
    return (
      <DevicesPartnerFilters
        setSelectedPartner={setSelectedPartner}
      />
    );
  };

  //  Rendering
  return (
    <div className="company-device-info-container">
      <div className="company-device-info">
        {renderInfoItem('Number of Devices', deviceByPartnerCount|| 0)}
        {renderInfoItem('Expected number of devices', countResponse?.data?.expected_devices || 0)}
        {renderDevicesFilters()}
      </div>
    </div>
  );
};

export default CompanyDevicesDetails;
