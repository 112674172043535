import React, { useEffect } from 'react'
import { Row } from 'reactstrap';
import AssessmentRow from '../../components/app-v4/common/dashboard/AssessmentRow';
import CybersecurityScore from '../../components/app-v4/cyvatar-pro/dashboard/cybersecurity-score/CybersecurityScore';
import ViewWrapper from '../../components/layout/ViewWrapper';
import MainScrollableContainer from '../../components/layout/MainScrollableContainer';
import DeviceManagementRow from '../../components/app-v4/cyvatar-pro/dashboard/DeviceManagementRow';
import IssueManagerRow from '../../components/app-v4/cyvatar-pro/dashboard/IssueManagerRow';
import SmartROI from '../../components/app-v4/cyvatar-pro/dashboard/SmartROI';
import TopFiveIssues from '../../components/app-v4/cyvatar-pro/dashboard/TopFiveIssues';
import CurrentSolutionStatus from '../../components/app-v4/cyvatar-pro/dashboard/solution-status/CurrentSolutionStatus';
import DashboardTimeFilter from '../../components/app-v4/cyvatar-pro/dashboard/DashboardTimeFilter';
import ProjectManagementRow from '../../components/app-v4/cyvatar-pro/dashboard/ProjectManagementRow';
import useMemoizedDispatch from '../../hooks/useMemoizedDispatch';
import { setBackLink } from '../../actions/navigation';
import useCustomMutation from '../../hooks/useCustomMutation';
import API from '../../api';
import RiskReconRow from '../../components/dashboard/risk-recon/RiskReconRow';

const CyvatarProHomeView = () => {
    // Component hooks
    const { dispatch } = useMemoizedDispatch();

    //  Initializing APIs
    const { DashboardAPI } = API;

    // Hide go back link
    useEffect(() => {
        dispatch(setBackLink(null, false, null, false));
    }, []);


    const {
        mutate: reloadData,
      } = useCustomMutation(
        (params) => DashboardAPI.handlers.reloadDemoDataSales(params),
        DashboardAPI.invalidators.reloadPostValue
      );

    // Render top row widgets
    const renderTopWidgets = () => {
        return (
            <Row className="no-gutters">
                <TopFiveIssues />
                <CurrentSolutionStatus />
                <SmartROI />
            </Row>
        )
    }

    return (
        <ViewWrapper
            layoutType="vertical"
            viewName="Cyvatar Pro Dashboard"
            hasImpersonationMode={true}
        >
            <div className='d-flex'>
                <div className="contained-view-width w-100 dashboard-time-filter-container">
                    <DashboardTimeFilter salesReloadDataMutator={reloadData}/>
                </div>
            </div>
            <MainScrollableContainer
                additionalClassName="contained-view-width"
            >
                {/* {renderTopWidgets()} */}
                <RiskReconRow />
                <AssessmentRow />
                <CybersecurityScore />
                <IssueManagerRow />
                <DeviceManagementRow />
                <ProjectManagementRow />
            </MainScrollableContainer>
        </ViewWrapper>
    )
}

export default CyvatarProHomeView;
