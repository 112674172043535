import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import ReactApexChart from "react-apexcharts";
import { Button } from 'reactstrap';
import { abbreviateNumber } from '../../../utils/helpers';
import { issueTypes } from '../../../constants/issues';
import { rollUpWidgetsIds } from '../../../constants/common';

const BasicDoughnutChart = (props) => {
    const {
        data,
        total,
        totalLabelUnit,
        height = 180,
        width = 300,
        pieOffsetX = -85,
        pieOffsetY = 0,
        customScale = 0.9,
        legendHeight = 162,
        legendWidth,
        colorsConfig = {},
        zeroLabel,
        onLegendClicked,
        onTotalClicked,
        customEmptyLabelClassName = 'no-report',
        showLegend = true,
        enableTooltip = true,
    } = props;
    const [series, setSeries] = useState(null);
    const [options, setOptions] = useState(null);
    const [labels, setLabels] = useState([]);
    const [colors, setColors] = useState(['#FFF']);

    const arrangeSeries = () => {
        if (!data) return [];
        const categories = [];
        const values = [];
        const dataPointColors = [];
        data.forEach((element) => {
            categories.push(element?.label);
            values.push(element?.value);
            dataPointColors.push(colorsConfig[element?.label] || '#FFF')
        })
        if (total === 0) {
            categories.push('')
            values.push(100)
            dataPointColors.push('#382a71')
        }
        setLabels(categories);
        setSeries(values);
        setColors(!_.isNil(dataPointColors) ? dataPointColors : ["#fff"])
    }

    const renderTotalUnit = () => {
        return (
            <>
                <div className="total-number">{abbreviateNumber(total)}</div>
                {totalLabelUnit}
            </>
        )
    }

    // Renders total count label when count is greater than zero
    const renderCount = () => {
        const isClickable = onTotalClicked && typeof (onTotalClicked) === 'function';
        return isClickable ? (
            <Button
                color="link"
                onClick={() => onTotalClicked(data)}
                aria-label={`${total} ${totalLabelUnit}`}
                tabIndex="0"
                aria-hidden="false"
            >
                {renderTotalUnit()}
            </Button>
        ) : renderTotalUnit();
    }

    // Renders label in middle of chart when the total count is zero
    const renderEmptyState = () => {
        return (
            <div className={customEmptyLabelClassName} >
                {zeroLabel || "The Cydekicks are getting everything in order."}
            </div>
        )
    }

    const renderTotalLabel = () => {
        const isBtnClickable = onTotalClicked && typeof (onTotalClicked) === 'function';
        return !isBtnClickable ? (
            <div className="total-data-label text-center"
                aria-label={`${total} ${totalLabelUnit}`}
                role="menuitem"
                tabIndex="0"
            >
                {total !== 0 ? renderCount() : renderEmptyState()}
            </div>
        ) : (
            <div className="total-data-label text-center"
                aria-hidden="true"
            >
                {total !== 0 ? renderCount() : renderEmptyState()}
            </div>
        )
    }

    useEffect(() => {
        arrangeSeries(data);
    }, [data]);

    useEffect(() => {
        setOptions({
            colors: colors,
            labels: labels,
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    offsetX: pieOffsetX,
                    offsetY: pieOffsetY,
                    donut: {
                        size: '87.5%'
                    },
                    customScale: customScale,
                    expandOnClick: false,
                }
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: ['#1B0C56'],
                width: 1,
                dashArray: 0,
            },
            legend: {
                show: showLegend,
                position: 'right',
                horizontalAlign: 'left',
                fontWeight: 600,
                fontSize: "13px",
                height: legendHeight,
                width: legendWidth,
                floating: true,
                markers: {
                    width: 10,
                    height: 10,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    radius: 0,
                },
                onItemHover: {
                    highlightDataSeries: false
                },
                formatter: function (seriesName, opts) {
                    return `${abbreviateNumber(opts.w.globals.series[opts.seriesIndex])} ${seriesName}`
                },
            },
            tooltip: {
                enabled: total !== 0 && enableTooltip,
                custom: function (opts) {
                    const { seriesIndex, w } = opts;
                    return (`
                    <div class="apexcharts-doughnut-tooltip apexcharts-basic-tooltip arrow-down">
                        <div class="series-label">${w?.globals?.labels[seriesIndex]}</div>
                        <div>${parseInt(w?.globals?.seriesPercent[seriesIndex] || 0)}% of all activities</div>
                    </div>
                  `);
                }
            },
            responsive: [{
                breakpoint: 300,
                options: {
                    legend: {
                        width: "120",
                    }
                },
            }],
            chart: {
                events: {
                    legendClick: (context, index) => {
                        if (!onLegendClicked || typeof (onLegendClicked) !== 'function') { return; }
                        const element = data[index];
                        onLegendClicked(element);
                    }
                }
            }
        })

    }, [colors, labels])

    return (!series || !options) ? null : (
        <div className={`basic-doughnut-chart-wrapper ${total === 0 ? 'total-zero-series' : ''}`}>
            {renderTotalLabel()}
            <ReactApexChart
                options={options}
                series={series}
                type="donut"
                height={height}
                width={width}
            />
        </div>
    )

}
export default BasicDoughnutChart;
