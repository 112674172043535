import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, NavItem, NavLink, Row, Col, Nav } from 'reactstrap';
import classnames from "classnames";

const CustomCardTabs = (props) => {
  const {
    tabs,
    selectedTab = 0,
    alignment = 'right',
    callback,
    className = ''
  } = props;
  const [customActiveTab, setcustomActiveTab] = useState(0);

  useEffect(() => {
    setcustomActiveTab(selectedTab)
  }, [selectedTab, setcustomActiveTab])

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
      if (callback) callback(tab)
    }
  }

  const renderTabContent = () => {
    if (!tabs || !tabs[customActiveTab]) { return null; }
    return (
      <TabPane key={`tab-${customActiveTab}`} tabId={customActiveTab}>
        {tabs[customActiveTab]?.content}
      </TabPane>
    );
  }

  return (
    <div className={`card-tabs ${className} ${alignment === 'right' ? 'clearfix right-tabs' : ''}`}>
      <Nav tabs className={`nav-tabs ${alignment === 'center' ? 'justify-content-center' : ''}`}>
        {tabs?.map((tab, index) => {
          if (!tab.name) { return null; }
          return (
            <NavItem key={`nav-tab-${index}`} className={`${tab.className || ''} ${index !== 0 ? 'left-divider' : ''}`}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === index
                })}
                onClick={() => {
                  toggleCustom(index);
                  if (tab.callback) tab.callback()
                }}
              >
                <span>{tab.name}</span>
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>
      <div>
        <TabContent activeTab={customActiveTab} className="w-100">
          {renderTabContent()}
        </TabContent>
      </div>

    </div>
  )
}

export default CustomCardTabs;
