import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';

const SearchBar = (props) => {
  const {
    callback,
    placeholder,
    value,
    customClassName,
    useSimpleContainer = false,
  } = props;

  const [term, setTerm] = useState('');
  const [debounceTerm, setDebounceTerm] = useState(term);

  useEffect(() => {
    setTerm(value || '');
  }, [value, setTerm]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      setDebounceTerm(term);
    }, 1000);
    return () => clearTimeout(timerID);
  }, [term]);

  useEffect(() => {
    callback(debounceTerm);
  }, [debounceTerm]);

  //  Function to render content
  const renderContent = () => {
    return (
      <>
        <i className="bx bx-search search-icon"></i>
        <Input
          type="text"
          className="form-control search-input"
          placeholder={placeholder}
          value={term}
          onChange={e => setTerm(e.target.value)}
        />
      </>
    );
  };

  return useSimpleContainer ? (
    <div className={customClassName}>
      {renderContent()}
    </div>
  ) : (
    <div className='search-box mr-2 mb-2 d-inline-block'>
      <div className="position-relative">
        {renderContent()}
      </div >
    </div >
  );
};

export default SearchBar;
