import React, { useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import API from '../../../api';
import { rollUpWidgetsIds } from '../../../constants/common';
import { onIssueTotalsSuccess, onReloadIssuesRollup } from '../../../actions/issues';
import useMemoizedDispatch from '../../../hooks/useMemoizedDispatch';
import { abbreviateNumber, isValidFunction } from '../../../utils/helpers';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { issueTypes } from '../../../constants/issues';
import issuesInfoSelector from '../../../selectors/issuesInfoSelector';
import IssuesByCriticalityBar from '../../app-v4/cyvatar-pro/dashboard/IssuesByCriticalityBar';
import { arrangeStatusData } from '../../../utils/dashboardHelpers';
import RollupCard from './RollupCard';
import useUserType from '../../../hooks/useUserType';
import { getIssueLabelByType } from '../../../utils/issuesHelpers';

const RollupWidget = (props) => {
    //  Parsing props
    const {
        sumAll = false,
        allowAllCompanies,
        sm = 6,
        lg = 6,
        company_id,
        containerClassName = '',
        cardClassName = '',
        extraColClassName = '',
        widgetOrder = [],
        handleRollupClick,
        solution_id = null,
        children: thirdElement = null,
        addTotalFilter,
        collapsed = false,
        issueType = issueTypes.VULNERABILITIES,
        criticalityBar = false,
        ariaLabel = '',
        customEmptyLabel,
        customEmptyLabelClassName,
    } = props;

    //  Initializing APIs
    const { IssueAPI } = API;

    //  Getting needed info from redux store
    const shouldReloadRollup = useSelector(state => issuesInfoSelector(state, 'shouldReloadRollup' ,issueType));

    //  Component hooks
    const { dispatch } = useMemoizedDispatch();
    const userType = useUserType();

    //  Calling API for data
    const {
        data: rollupData,
        error: rollupDataError,
        isLoading: rollupDataLoading,
        refetch: refetchRollUpData,
    } = useCustomQuery(
        [IssueAPI.keys.getRollupData, {
            company_id,
            params: {
                open_only: true,
                solution_id,
                grc_only: issueTypes.GRC === issueType
            }
        }, allowAllCompanies],
        ({ queryKey }) => IssueAPI.handlers.getRollupData(queryKey[1], queryKey[2])
    );

    //  Watching roll up data
    useEffect(() => {
        if (!rollupData) { return; }
        dispatch(onIssueTotalsSuccess(rollupData, issueType, userType));
    }, [rollupData]);

    //  Watching should reload flag changes
    useEffect(() => {
        if (!shouldReloadRollup) { return; }
        refetchRollUpData();
        dispatch(onReloadIssuesRollup(false, issueType));
    }, [shouldReloadRollup]);

    const retryRequest = () => {
        if (!company_id) { return; }
        refetchRollUpData();
    }

    const callArrangeStatusData = () => {
        const componentId = "current-status-rollup-widget";
        const arrangedData = arrangeStatusData(rollupData, sumAll, null, issueType, userType, componentId, collapsed);
        const title = `Current Status Rollup${collapsed ? `: ${abbreviateNumber(arrangedData?.total)}` : ''}`;
        return {
            ...arrangedData,
            title
        }
    }

    const arrangeCriticalityData = () => {
        const data = rollupData?.by_severity;
        let sum = 0;
        const arrangedData = [];
        if (data) {
            Object.keys(data).forEach((key) => {
                const element = data[key];
                if (element?.hidden) { return; }
                arrangedData.push({ label: element?.label, value: parseInt(element?.total || 0), id: key });
                sum += parseInt(element?.total || 0);
            });
        }

        return {
            data: arrangedData,
            total: sum,
            colorsConfig: {
                'Critical': '#FF5E54',
                'High': '#F1A04C',
                'Medium': '#F5D302',
                'Low': '#0EB082',
            },
            title: !collapsed ? `Open ${getIssueLabelByType(issueType, false)} by criticality` : `Open ${getIssueLabelByType(issueType, true)}: ${abbreviateNumber(sum)}`,
            subtitle: `Open ${getIssueLabelByType(issueType, false)} by criticality:`,
            unitLabel: `open ${getIssueLabelByType(issueType, false)}`,
            titleTooltip: "set",
            componentId: "criticality-rollup-widget"
        };
    }

    //  Function to handle legend click
    const onLegendClicked = (element, widgetType) => {
        if (!isValidFunction(handleRollupClick)) { return; }
        handleRollupClick(element, widgetType, false);
    };

    const renderIssuesByCriticalityBarChart = (widgetType) => {
        return (
            <IssuesByCriticalityBar
                containerClassName='h-100 rollup-widget-container align-self-lg-stretch col-sm-12 col-lg-6 issue-manager-bar-chart'
                height={190}
                onLegendClicked={(element) => onLegendClicked(element, widgetType)}
                titleOffsetX={10}
                titleOffsetY={130}
            />
        )
    }

    const renderRollup = (props, widgetType) => {
        const {
            data,
            colorsConfig,
            total,
            unitLabel,
            componentId,
            titleTooltip,
            title,
            subtitle
        } = props;


        return (
            <Col
                key={`rollup-widget-${componentId}`}
                sm={sm}
                lg={lg}
                className={`rollup-widget-container align-self-start align-self-lg-stretch mb-2 ${extraColClassName}`}
            >
                <RollupCard
                    componentId={componentId}
                    title={title}
                    subtitle={subtitle}
                    titleTooltip={titleTooltip}
                    colorsConfig={colorsConfig}
                    collapsed={collapsed}
                    cardClassName={cardClassName}
                    isLoading={rollupDataLoading}
                    errorMessage={rollupDataError ? "Oops! our fault, reload again." : null}
                    retryRequest={retryRequest}
                    data={data}
                    unitLabel={unitLabel}
                    total={total}
                    widgetType={widgetType}
                    issueType={issueType}
                    handleRollupClick={handleRollupClick}
                    addTotalFilter={addTotalFilter}
                    customEmptyLabel={customEmptyLabel}
                    customEmptyLabelClassName={customEmptyLabelClassName}
                />
            </Col>
        )
    }

    //  Function to render content
    const renderWidgets = () => {
        return widgetOrder.map((widgetType) => {
            const widgetConfig = widgetType === rollUpWidgetsIds.CRITICALITY ? arrangeCriticalityData() : callArrangeStatusData();
            const shouldRenderCriticalityBar = widgetType === rollUpWidgetsIds.CRITICALITY && criticalityBar && !collapsed
            return shouldRenderCriticalityBar ? renderIssuesByCriticalityBarChart(widgetType) : renderRollup(widgetConfig, widgetType);
        });
    };

    //ARIA
    const ariaIssuesSummary = () => {
        const criticalityData = arrangeCriticalityData();
        const statusData = callArrangeStatusData();
        const ariaCriticality = `${criticalityData?.total} Open issues by criticality`;
        const ariaStatus = `${statusData?.total} Current Status Rollup issues`;
        const ariaSummary = `There are ${ariaCriticality} and ${ariaStatus}`;
        return ariaSummary;
    };

    const ariaDescription = !collapsed ? `Currently on ${ariaLabel}, please scroll down to access the button group of filters or use the More Filters dialog to filter issues.` : `Currently on ${ariaLabel}, it's possible to filter issues here.`;

    //  Rendering
    return (
        <div
            className={containerClassName}
            aria-label={ariaLabel}
            tabIndex="0"
            aria-description={`${ariaDescription} ${ariaIssuesSummary()}`}
        >
            {renderWidgets()}
            {thirdElement}
        </div>
    )
}
export default RollupWidget;
