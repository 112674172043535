import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Table } from "reactstrap";
import { riskDomainDisplayInfo } from "../../../constants/riskReconSecurityDomains";
import downLine from '../../../assets/fonts/down-line.svg';
import upLine from '../../../assets/fonts/up-line.svg';
import straightLine from '../../../assets/fonts/straigh-line.svg';
import InfoTooltip from '../tooltips/InfoTooltip';

const RiskRating = ({ domainRatings, industryTrends }) => {
  const [displayableDomains, setDisplayableDomains] = useState([]);
  useEffect(() => {
    if (domainRatings) {
      const displayableDomains = Object.entries(domainRatings)
        .map(([domain, domainInfo]) => {
          const domainDisplayInfo = riskDomainDisplayInfo[domain];
          if (!domainDisplayInfo || !domainInfo) return null;
          return {
            label: domainDisplayInfo.label,
            description: domainDisplayInfo.description,
            order: domainDisplayInfo.order,
            grade: domainInfo.rating_v2,
            score: domainInfo.rating_numeric,
            trend: domainInfo.trend || 0,
          };
        })
        .filter(Boolean)
        .sort((a, b) => a.order - b.order);
  
      setDisplayableDomains(displayableDomains);
    }
  }, [domainRatings]);

  const getOrdinalNumber = (number) => {
    const s = ["th", "st", "nd", "rd"];
    const v = number % 100;
    const roundedV = Math.round(v);
    return roundedV + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  const renderTooltip = (domain, domainId) => {
    return (
      <InfoTooltip
        id={domainId}
        label={domain.label}
        showLabel={true}
        info={domain.description || domain.label}
        iconClassName="risk-recon-domain-info"
      />
    )
  }

  const renderDomains = (side) => {
    const halfCount = Math.round(displayableDomains.length / 2);
    const startIndex = side === 'left' ? 0 : halfCount;
    const endIndex = side === 'left' ? halfCount : displayableDomains.length;
    return displayableDomains.slice(startIndex, endIndex).map((domain, index) => {
      const roundedTrend = parseFloat(domain.trend).toFixed(1);
      const domainId = `risk-recon-domain-${side}${index}`;
      const imageSrc = domain.trend > 0 ? upLine : domain.trend < 0 ? downLine : straightLine;
      return (
        <>
          <tr key={`${domain.label}-${index}`}>
            <td>
              {renderTooltip(domain, domainId)}
            </td>
            <td>
              <div className={`rating-badge rating-${domain.grade.toLowerCase()}`}>
                <span className="rating-grade">{domain.grade.toUpperCase()}</span>
                <span className="rating-score">{domain.score}</span>
              </div>
            </td>
            <td>
              <div className='trend-container'>
                <span className="trend-value">{roundedTrend}</span>
                <img src={imageSrc} alt="trend" />
              </div>
            </td>
          </tr>
        </>
      )
    });
  };

  const renderDomainTable = (side) => {
    return (
      <Table className="table-risk-recon table-centered" key={`domain-table${side}`}>
        <thead>
          <tr>
            <th>Domain</th>
            <th>Rating</th>
            <th>Trend</th>
          </tr>
        </thead>
        <tbody>
          {renderDomains(side)}
        </tbody>
      </Table>
    )
  };

  const returnNoDataToDisplay = () => {
    return (
      <div className='industry-rating-container'>
        <div className='industry-rating-content industry-rating-title'>Industry Ratings</div>
        <div className='industry-rating-content industry-rating-value-label'>No Data to Display</div>
      </div>
    )
  }

  const renderIndustryTrends = () => {
    if (!industryTrends || industryTrends.industry === 'Uncategorized') return returnNoDataToDisplay();
    const { analysis_percentile: analysisPercentile, industry_mean: industryMean, industry } = industryTrends;
    const percentilConvertion = getOrdinalNumber(analysisPercentile.overall * 100);
    return (
      <div className='industry-rating-container'>
        <div className='industry-rating-content industry-rating-title'>Industry Ratings</div>
        <div className='industry-rating-content'>
          <div className='industry-rating-value'>{industryMean.overall}</div>
          <div className='industry-rating-label'>Industry Average</div>
        </div>
        <div className='industry-rating-content'>
          <div className='industry-rating-value'>{percentilConvertion}</div>
          <div className='industry-rating-label'>Percentil Rank</div>
        </div>
        <div className='industry-rating-content'>
          <div className='industry-rating-value'>{industry}</div>
          <div className='industry-rating-label'>Industry</div>
        </div>
      </div>
    )
  }

  return (
    <Card className="risk-recon-score-card h-100">
      <CardBody>
        <Row className="industry-ratings-row">
          {renderIndustryTrends()}
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={6}>
            {renderDomainTable('left')}
          </Col>
          <Col xs={12} sm={6} lg={6}>
            {renderDomainTable('right')}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default RiskRating;